import { copyToClipboard } from '@app/utils/copyToClipboard';
import { Input, NotificationModal } from '@components/ui';
import { useFileShareTokenQuery } from '@dieterApi/file/useFileShareTokenQuery';
import { UserTopic } from '@dieterApi/user/useUserQuery';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button as MuiButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { PartnerTableMeta } from './typesAndEnums';

export const RequestTomsModal = ({
  topic,
  tableMeta,
  ...props
}: {
  open: boolean;
  onClose: () => void;
  topic: UserTopic;
  tableMeta?: PartnerTableMeta;
}) => {
  // only temporary in here, before we have proper UI
  const [hasCopied, setHasCopied] = useState(false);
  const [getFileUploadToken, { data: tokenData }] = useFileShareTokenQuery();
  const token = tokenData?.getFileShareToken;
  const url = token ? window.location.origin + '/file?token=' + token : '';

  useEffect(() => {
    const index = tableMeta?.rowData[2] as string;
    const partnerName = tableMeta?.rowData[0] as string;
    index &&
      partnerName &&
      getFileUploadToken({
        variables: {
          type: 'upload',
          topicId: topic.id,
          description: `${partnerName} - Technische und Organisatorische Maßnahmen`,
          userValueIndex: index,
        },
      });
  }, [tableMeta]);

  const handleCopyClick = () => {
    copyToClipboard(url).then(() => setHasCopied(true));
  };

  return (
    <NotificationModal {...props}>
      <div className="flex flex-col gap-10">
        <h3 className="font-medium text-xl">TOMs anfordern</h3>
        <p>
          Bitte versende den folgenden Link an deinen Vertragspartner. Dieser leitet ihn ohne Anmeldung zu einer
          Upload-Seite weiter. Die dort hochgeladene Datei, wird automatisch diesem Vertragspartner zugeordnet.
        </p>
        <div className="flex w-100">
          <div className="flex-1">
            <Input
              type="text"
              value={url}
              onFocus={(e) => e.target.select()}
              textSize={'small'}
              readOnly={true}
              data-testid={'input-share-url'}
            />
          </div>
          <div>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={hasCopied ? 'Kopiert!' : 'In Zwischenablage kopieren'}
            >
              <MuiButton
                onClick={handleCopyClick}
                onMouseLeave={() => setTimeout(() => setHasCopied(false), 1000)}
                data-testid={'button-copy-clipboard'}
              >
                <ContentCopyIcon />
              </MuiButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </NotificationModal>
  );
};
