import AppProvider from '@app/app-provider.tsx';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloClientProvider } from './context/ApolloClientContext';
import { Root } from './routes/Root';
import { ConsentService, executeOnConsent } from './utils/checkConsent';

interface TracifyPurchaseOptions {
  amount: string;
  orderId: string;
  currency: 'EUR';
  email?: string;
  dateTime?: Date;
}

function initSentry() {
  const isDev = import.meta.env.MODE === 'development';
  !isDev &&
    Sentry.init({
      dsn: 'https://212ceca2ee0365386c8c77fef40fe130@o4506217438380032.ingest.sentry.io/4506217440280576',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 1.0,
      environment: import.meta.env.MODE,
    });
}

declare global {
  interface Window {
    UC_UI: {
      getServicesBaseInfo: () => Array<Record<string, any>>;
      acceptService: (serviceId: string) => void;
      showSecondLayer: () => void;
    };
    tracify: {
      capturePurchase(options: TracifyPurchaseOptions): void;
      captureProductView(): void;
      captureAddToCart(): void;
    };
  }
}

function App() {
  useEffect(() => {
    // check if UC_UI object is available, if not retry every 500ms until available
    // we only want to initialize sentry if the user has given consent
    executeOnConsent(ConsentService.Sentry, initSentry);
  }, []);

  return (
    <ApolloClientProvider>
      <AppProvider>
        <HelmetProvider>
          <Root />
        </HelmetProvider>
      </AppProvider>
    </ApolloClientProvider>
  );
}

export default App;
