import { gql, useMutation } from '@apollo/client';
import { Employee, EMPLOYEE_FIELDS, User } from '@dieterApi/user/useUserQuery';

const UPDATE_EMPLOYEE = gql`
  ${EMPLOYEE_FIELDS}
  mutation updateEmployee($employeeId: String!, $name: String!) {
    updateEmployee(employeeId: $employeeId, name: $name) {
      ...UserEmployeeFields
    }
  }
`;

export interface UpdateEmployeeResult {
  updateEmployee: Employee;
}

export interface UpdateEmployeeInput {
  employeeId: string;
  name: string;
}

export function useEmployeeUpdateMutation() {
  return useMutation<UpdateEmployeeResult, UpdateEmployeeInput>(UPDATE_EMPLOYEE, {
    // optimisticResponse: (input) => {
    //   return {
    //     updateEmployee: {
    //       name: input.name,
    //       value: input.value,
    //       index: input.index || 'UNIQUE',
    //     },
    //   };
    // },

    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: (user: User) => {
            const newEmployees = user.company?.employees ? [...user.company.employees] : [];
            // pop the item if it already exists (id matches)
            const index = newEmployees.findIndex((employee) => employee.id === data?.updateEmployee.id);
            if (index !== -1) {
              newEmployees.splice(index, 1);
            }
            // push the new item
            data?.updateEmployee && newEmployees.push(data?.updateEmployee);
            return { ...user, company: { ...user.company, employees: newEmployees } };
          },
        },
      });
    },
  });
}
