export enum ConsentService {
  Hubspot = 'ry0QcNodoWQ',
  Sentry = 'rH1vNPCFR',
}

export function executeOnConsent(service: ConsentService, callback: () => void) {
  const interval = setInterval(() => {
    // console.log(`Checking for consent of service ${service}...`);
    if (window.UC_UI) {
      const services = window.UC_UI.getServicesBaseInfo();
      const sentry = services.find((s) => s.id === service);
      if (sentry?.consent.status === true) {
        // console.log(`Consent for service ${service} found!`);
        clearInterval(interval);
        callback();
      }
    }
  }, 1000);
}

export const giveConsent = (service: ConsentService) => {
  if (window.UC_UI) {
    window.UC_UI.acceptService(service);
  }
};
