import { Task } from '@dieterApi/task/useTaskQuery';
import {
  ApplicationStatus,
  ApplicationType,
  UserTopicStatusApplicationsQuestionnaires,
  UserValue,
} from '@dieterApi/user/useUserQuery';
import { pickLatest } from './pickLatest';

export const calculateTopicStatus = (
  topic: UserTopicStatusApplicationsQuestionnaires,
  isOnboardingTopic?: boolean,
  userValues?: UserValue[],
  tasks?: Task[]
): ApplicationStatus => {
  if (topic.status === ApplicationStatus.DISABLED && isOnboardingTopic) return ApplicationStatus.PENDING;
  if (topic.status) return topic.status;
  // We define the TopicStatus as follows:
  // - A topic consist of multiple applications, but only those of ApplicationType.Primary and ApplicationType.Secondary are relevant for status calculation
  // - A topic is NOT_STARTED if all applications are NOT_STARTED
  // - A topic is IN_PROGRESS if at least one application is IN_PROGRESS
  // - A topic is COMPLETED if all applications are COMPLETED
  // An application can have multiple questionnaires, but only the latest one is relevant for status calculation and can be extracted with the function pickLatest
  // - An application is IN_PROGRESS if a questionnaire exists, and its progress is < 1
  // - An application is NOT_STARTED if there is no questionnaire or the questionnaire's progress is 0
  // - An application is COMPLETED if a questionnaire exists, and its progress is 1

  // We first filter out all applications that are not of ApplicationType.Primary or ApplicationType.Secondary
  let primaryAndSecondaryApplications = topic.applications.filter(
    (app) => app.type === ApplicationType.Primary || app.type === ApplicationType.Secondary
  );

  // For the special case of app-video-documentation-create we check for the existence of uservalue
  // with key VIDEO_SURVEILLANCE_EXISTS = "Ja" and discard this application if the value is not present
  if (topic.id === 'topic-video' && userValues) {
    const videoSurveillanceExists = userValues.find((value) => value.key === 'VIDEO_SURVEILLANCE_EXISTS');
    if (!videoSurveillanceExists || videoSurveillanceExists.value !== 'Ja') {
      primaryAndSecondaryApplications = primaryAndSecondaryApplications.filter(
        (app) => app.id !== 'app-video-documentation-create'
      );
    }
  }

  // Then we determine the status of each application
  const applicationStatuses = primaryAndSecondaryApplications.map((app) => {
    // find task which corresponds to application
    const task = tasks?.find((task) => task.application?.id === app.id);
    if (task?.isIgnored) return ApplicationStatus.COMPLETED;

    // If there is no questionnaire, the application is NOT_STARTED
    if (!app.questionnaires || app.questionnaires.length === 0) return ApplicationStatus.NOT_STARTED;

    // If there is a questionnaire, we pick the latest one
    const latestQuestionnaire = pickLatest(app.questionnaires);

    // If the progress is 0, the application is NOT_STARTED
    if (latestQuestionnaire!.progress === 0) return ApplicationStatus.NOT_STARTED;

    // If the progress is < 1, the application is IN_PROGRESS
    if (latestQuestionnaire!.progress < 1) return ApplicationStatus.IN_PROGRESS;

    // If the progress is 1, the application is COMPLETED
    if (latestQuestionnaire!.progress === 1) return ApplicationStatus.COMPLETED;
  });

  // If all applications are NOT_STARTED, the topic is NOT_STARTED
  if (applicationStatuses.every((status) => status === ApplicationStatus.NOT_STARTED))
    return ApplicationStatus.NOT_STARTED;

  // If all applications are COMPLETED, the topic is COMPLETED
  if (applicationStatuses.every((status) => status === ApplicationStatus.COMPLETED)) return ApplicationStatus.COMPLETED;

  // If at least one application is IN_PROGRESS, the topic is IN_PROGRESS
  if (
    applicationStatuses.some((status) => [ApplicationStatus.IN_PROGRESS, ApplicationStatus.COMPLETED].includes(status!))
  )
    return ApplicationStatus.IN_PROGRESS;

  // If none of the above conditions are met, the topic is NOT_STARTED
  return ApplicationStatus.NOT_STARTED;
};
