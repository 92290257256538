import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { OfferType } from '@app/context/NavigationContext';
import { NavigationSubsection, Topics } from '@app/context/constants';

import { Locales } from '@components/block/Header/LangSelect';
import { CHAT_FIELDS, Chat } from '@dieterApi/chat/useInitiateChatMutation';
import { PROCESSING_CONSENT_FIELDS, ProcessingConsent } from '@dieterApi/questionnaire/useAddProcessingConsentMutation';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import { useTranslation } from 'react-i18next';
import { SCAN_RESULT_FIELDS, ScanResultData } from '../scan/useScanResultQuery';

export const USERVALUE_FIELDS = gql`
  fragment UserValueFields on UserValue {
    id
    key
    value
    index
    createdAt
  }
`;

export const QUESTIONNAIRE_FIELDS = gql`
  fragment UserQuestionnaireFields on LocalQuestionnaire {
    id
    questionnaireId
    companyId
    createdAt
    updatedAt
    sectionModifiedAt
    state
    isBroken
    sectionStates
    progress
    sectionProgress
    hash
    accessToken
    applicationVersion {
      version
      versionDate
    }
    documentConfirmations {
      name
      employeeId
      createdAt
      documentNumber
    }
    userValueIndex
  }
`;

export const FILE_FIELDS = gql`
  fragment UserFileFields on File {
    id
    name
    size
    uri
    url
  }
`;

export const TOPIC_FIELDS = gql`
  ${FILE_FIELDS}
  fragment UserTopicFields on Topic {
    id
    title
    titleLocales {
      de
      en
    }
    order
    helpText
    subItemLocales {
      id
      title
    }
    enabled
    tags {
      tag
    }
    files {
      ...UserFileFields
    }
    hasFiles
    shouldHaveFiles
  }
`;

export const APPLICATION_FIELDS = gql`
  fragment UserAppFields on Application {
    id
    title
    order
    sectionLabels
    documentLabels
    versions {
      version
      versionDate
    }
    splitApplication
    splitDocument
    status
    type
    class
    tags {
      tag
    }
  }
`;

export const EMPLOYEE_FIELDS = gql`
  fragment UserEmployeeFields on Employee {
    id
    name
    createdAt
  }
`;

export const COMPANY_FIELDS = gql`
  ${EMPLOYEE_FIELDS}
  ${SCAN_RESULT_FIELDS}
  ${PROCESSING_CONSENT_FIELDS}
  fragment UserCompanyFields on Company {
    id
    name
    city
    street
    zip
    phone
    website
    subscription
    isPrincipal
    isAgency
    isDpo
    couponCode
    coupons {
      code
      createdAt
    }
    stripeCustomerId
    usercentricsSettingsId
    wixAppInstanceId
    topicsActivated
    numberOfTickets
    numberOfScans
    features
    processingConsents {
      ...ProcessingConsentFields
    }
    users {
      id
      createdAt
      email
      confirmed
      role
      lastLogin
      mfaEnabled
    }
    employees {
      ...UserEmployeeFields
    }
    questionnaires {
      ...UserQuestionnaireFields
      application {
        ...UserAppFields
      }
    }
    scanResult {
      ...ScanResultFields
    }
  }
`;

export const USER_FIELDS = gql`
  ${QUESTIONNAIRE_FIELDS}
  ${APPLICATION_FIELDS}
  ${TOPIC_FIELDS}
  ${COMPANY_FIELDS}
  ${USERVALUE_FIELDS}
  ${CHAT_FIELDS}

  fragment UserFields on User {
    id
    createdAt
    firstName
    lastName
    email
    confirmed
    source
    imgUrl
    mailSent
    isAdmin
    role
    cohort
    isAnonymous
    isSubscriber
    isTrialing
    maxTenants
    marketingConsent
    conditionsConsent
    navState
    subscription
    hasPassword
    previousLogin
    mfaEnabled
    mfaVerified
    experimental
    locale
    company {
      ...UserCompanyFields
    }
    companies {
      ...UserCompanyFields
    }
    topics {
      ...UserTopicFields
      status
      applications {
        ...UserAppFields
        questionnaires {
          ...UserQuestionnaireFields
        }
      }
    }
    questionnaires {
      ...UserQuestionnaireFields
      application {
        ...UserAppFields
        topic {
          ...UserTopicFields
        }
      }
    }
    userValues {
      ...UserValueFields
    }

    roqToken
    hubspotIdToken
    chats {
      ...ChatFields
    }
  }
`;

export const GET_USER = gql`
  ${USER_FIELDS}
  query GetUser($userId: String) {
    getUser(userId: $userId) {
      ...UserFields
    }
  }
`;

export interface User {
  id: string;
  createdAt: Date;
  company: UserCompany;
  companies: UserCompany[];
  firstName?: string;
  lastName?: string;
  email?: string;
  role: UserRole;
  locale: Locales;
  confirmed: boolean;
  source: 'local' | 'linkedin' | 'shopify' | 'wix';
  imgUrl?: string;
  mailSent: number;
  maxTenants: number;
  cohort: 'A' | 'B' | 'C';
  isAdmin: boolean;
  isAnonymous: boolean;
  isSubscriber: boolean;
  isTrialing: boolean;
  marketingConsent: boolean;
  conditionsConsent: boolean;
  navState: string;
  subscription: OfferType;
  hasPassword: boolean;
  previousLogin: string;
  userValues: UserValue[];
  experimental: boolean;
  mfaEnabled: boolean;
  mfaVerified?: string;
  topics: UserTopicStatusApplicationsQuestionnaires[];
  questionnaires?: UserQuestionnaireApplicationTopic[];
  token?: string;
  roqToken?: string;
  hubspotIdToken?: string;
  getValue: (key: string, index?: string) => string | undefined;
  hasFeature: (feature: ProductFeature) => boolean;
  isReadOnly?: boolean;
  chats: Chat[];
}

export enum UserRole {
  Admin = 'Admin',
  Editor = 'Editor',
  ReadOnly = 'ReadOnly',
}

export interface UserValue {
  id: string;
  key: string;
  value: string;
  index: string;
  createdAt: string;
}

export type UserApplicationQuestionnaires = UserApplication & {
  questionnaires?: UserQuestionnaire[];
};

export type UserTopicApplicationsQuestionnaires = UserTopic & {
  applications: UserApplicationQuestionnaires[];
};
export type UserTopicStatusApplicationsQuestionnaires = UserTopicApplicationsQuestionnaires & {
  status: ApplicationStatus;
};

export type UserApplicationTopic = UserApplication & { topic: UserTopic };

export type UserQuestionnaireApplication = UserQuestionnaire & {
  application: UserApplication;
};

export type UserQuestionnaireApplicationTopic = UserQuestionnaire & {
  application: UserApplicationTopic;
};

export interface UserCompany {
  id: string;
  name: string;
  city: string;
  street: string;
  zip: string;
  phone: string;
  website: string;
  users: {
    id: string;
    createdAt: string;
    email: string;
    confirmed: boolean;
    role: UserRole;
    mfaEnabled: boolean;
    lastLogin: string;
  }[];
  subscription: OfferType;
  processingConsents: ProcessingConsent[];
  isPrincipal: boolean;
  isAgency: boolean;
  isDpo: boolean;
  numberOfTickets?: number;
  numberOfScans: number;
  topicsActivated: Topics[];
  couponCode?: string;
  coupons: Coupon[];
  stripeCustomerId?: string;
  usercentricsSettingsId?: string;
  wixAppInstanceId?: string;
  features: ProductFeature[];
  employees: Employee[];
  questionnaires: UserQuestionnaireApplication[];
  scanResult: ScanResultData;
}

export interface Coupon {
  code: string;
  createdAt: string;
}

export type ProductFeature = 'MultiTenancy' | 'eSignatures' | 'Usercentrics';

export interface Employee {
  id: string;
  createdAt: string;
  name: string;
}

export interface UserFile {
  id: string;
  name: string;
  size: number;
  uri: string;
  url?: string;
}

export enum ApplicationStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  DISABLED = 'DISABLED',
  PENDING = 'PENDING',
  EXPERIMENTAL = 'EXPERIMENTAL',
}

export enum ApplicationType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  ListEntryLong = 'ListEntryLong',
  ListEntryShort = 'ListEntryShort',
  ListContract = 'ListContract',
}

export enum ApplicationClass {
  Training = 'Training',
  Document = 'Document',
  Contract = 'Contract',
  Analysis = 'Analysis',
}

export interface UserTopic {
  id: Topics;
  title: Topics;
  titleLocales: { de: string; en: string };
  order: number;
  helpText: string[];
  subItemLocales: { id: NavigationSubsection; title: string }[];
  enabled: boolean;
  tags: Tag[];
  files: UserFile[];
  hasFiles: boolean;
  shouldHaveFiles: boolean;
}

export interface UserApplication {
  id: ApplicationIDs;
  title: ApplicationIDs;
  order: number;
  sectionLabels: string[];
  documentLabels: string[];
  versions: ApplicationVersion[];
  splitApplication: boolean;
  splitDocument: boolean;
  status?: ApplicationStatus;
  type: ApplicationType;
  class: ApplicationClass;
  tags?: Tag[];
}

export type ApplicationIDs =
  | 'app-checkup'
  | 'app-ds-mgmt'
  | 'app-dsb-training'
  | 'app-dsv-training'
  | 'app-dsv-incident'
  | 'app-av-training'
  | 'app-av-contract-partners'
  | 'app-av-contract-av'
  | 'app-av-contract-scc'
  | 'app-av-contract-jc'
  | 'app-toms-training'
  | 'app-toms-create'
  | 'app-toms-authorization-concept-create'
  | 'app-dse-training'
  | 'app-dse-privacy-policy-create'
  | 'app-dse-imprint-create'
  | 'app-info-training'
  | 'app-info-create'
  | 'app-info-consent-create'
  | 'app-video-training'
  | 'app-video-documentation-create'
  | 'app-rights-training'
  | 'app-rights-request-create'
  | 'app-rights-request-right-to-rectification'
  | 'app-rights-request-right-to-restriction'
  | 'app-rights-request-right-to-data-portability'
  | 'app-rights-request-right-to-objection'
  | 'app-rights-request-right-to-revocation'
  | 'app-rights-request-right-to-information'
  | 'app-rights-request-right-to-deletion'
  | 'app-rights-request-right-to-denial-automated-decision'
  | 'app-rights-request-extension-deadline'
  | 'app-employees-training'
  | 'app-employees-commitment-create'
  | 'app-employees-information-create'
  | 'app-employees-applicant-information-create'
  | 'app-employees-basic-training'
  | 'app-vvz-training'
  | 'app-vvz-create'
  | 'app-deletions-concept-create'
  | 'app-dsfa-training'
  | 'app-experimental-toms'
  | 'app-meta';

export interface ApplicationVersion {
  version: number;
  versionDate: string;
}

export interface Tag {
  tag: TagValues;
}

export type TagValues =
  | 'noresume'
  | 'nofiles'
  | 'analysis'
  | 'dummy'
  | 'internal'
  | 'external'
  | 'experimental'
  | 'action'
  | 'meta'
  | 'public'
  | 'AVV'
  | 'SCC'
  | 'JC'
  | 'docx'
  | 'pdf'
  | 'html'
  | 'consentlink'
  | 'nosubitems'
  | 'experimental-invisible'
  | 'allopen'
  | 'EXTEND_DEADLINE'
  | 'en'
  | 'de';

export interface UserQuestionnaire {
  id: string;
  questionnaireId: string;
  companyId: string;
  createdAt: string;
  updatedAt: string;
  sectionModifiedAt: string[];
  applicationVersion?: ApplicationVersion;
  state: QuestionnaireState;
  isBroken: boolean;
  sectionStates: QuestionnaireState[];
  progress: number;
  sectionProgress: number[];
  hash: string;
  documentConfirmations: DocumentConfirmation[];
  userValueIndex?: string;
  accessToken?: string;
  isNew?: boolean[]; // this is added after backend response by the frontend (NavigationContext)
}

export interface DocumentConfirmation {
  name: string;
  employeeId: string;
  createdAt: string;
  documentNumber: number;
}

export interface GetUserResult {
  getUser: User;
}

export interface GetUserInput {
  userId?: string;
}

export function useUserQuery() {
  const { i18n } = useTranslation();
  return useQuery<GetUserResult, GetUserInput>(GET_USER, {
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      if (!data?.getUser) return;
      i18n.changeLanguage(data.getUser.locale);
    },
  });
}

export function useUserLazyQuery() {
  return useLazyQuery<GetUserResult, GetUserInput>(GET_USER, {
    fetchPolicy: 'no-cache',
  });
}
