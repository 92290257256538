import { Provider as BalancerProvider } from 'react-wrap-balancer';

import { ApolloProvider } from '@apollo/client';
// import client from '@app/client.ts';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';
import { useApolloClient } from './context/ApolloClientContext';
import { FlowProvider } from './context/FlowContext';
import { MessageProvider } from './context/MessageContext';
import { MessageQueueProvider } from './context/MessageQueueContext';
import { NavigationProvider } from './context/NavigationContext';
import { OrderProvider } from './context/OrderContext';
import { TaskProvider } from './context/TaskContext';
import { UserProvider } from './context/UserContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#a95ecd',
    },
    secondary: {
      main: '#fdd213',
    },
    success: {
      main: '#91ab46;',
    },
  },
  typography: {
    fontFamily: ['DieterRubik'].join(','),
  },
  zIndex: {
    snackbar: 10000,
  },
});

interface IProps {
  children: ReactNode;
}

function AppProvider({ children }: IProps) {
  const { client } = useApolloClient();
  if (!client) return null;
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={5} classes={{ containerRoot: 'z-alert' }}>
          <UserProvider>
            <NavigationProvider>
              <MessageQueueProvider>
                <MessageProvider>
                  <TaskProvider>
                    <FlowProvider>
                      <OrderProvider>
                        <BalancerProvider>{children}</BalancerProvider>
                      </OrderProvider>
                    </FlowProvider>
                  </TaskProvider>
                </MessageProvider>
              </MessageQueueProvider>
            </NavigationProvider>
          </UserProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default AppProvider;
