import { gql, useMutation } from '@apollo/client';
import { USER_FIELDS, User } from './useUserQuery';

const UPDATE_USER_EMAIL = gql`
  ${USER_FIELDS}
  mutation UpdateUserEmail($email: String!, $marketingConsent: Boolean) {
    updateUserEmail(email: $email, marketingConsent: $marketingConsent) {
      ...UserFields
    }
  }
`;

export interface UpdateUserEmailResult {
  updateUserEmail: User;
}

export interface UpdateUserEmailInput {
  email: string;
  marketingConsent?: boolean;
}

export function useUserUpdateEmailMutation() {
  return useMutation<UpdateUserEmailResult, UpdateUserEmailInput>(UPDATE_USER_EMAIL, {
    fetchPolicy: 'no-cache',
    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: () => (data && data.updateUserEmail ? data.updateUserEmail : null),
        },
      });
    },
  });
}
