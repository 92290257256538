import { ProductFeature, User, UserRole, useUserQuery } from '@dieterApi/user/useUserQuery';
import React, { createContext, useContext, useEffect } from 'react';
import { useApolloClient } from './ApolloClientContext';

interface IUserContext {
  user: User | undefined;
  userLoading: boolean;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

interface Props {
  children: React.ReactNode;
}

export function UserProvider({ children }: Props) {
  const { data: userData, loading: userLoading } = useUserQuery();
  const { wsClient } = useApolloClient();

  const user = userData?.getUser;

  let userWithMethods: User | undefined = undefined;

  // add custom methods to user object
  if (user) {
    // copy user object but allow extension
    userWithMethods = { ...user };
    userWithMethods.getValue = function (key: string, index?: string) {
      const userValue = index
        ? this.userValues.find((uv) => uv.key === key && uv.index === index)
        : this.userValues.find((uv) => uv.key === key);
      return userValue?.value;
    };
    userWithMethods.hasFeature = function (feature: ProductFeature) {
      return this.company?.features.includes(feature) || false;
    };
    userWithMethods.isReadOnly = user.role === UserRole.ReadOnly;
  }

  useEffect(() => {
    if (user?.company.stripeCustomerId && window.tap) {
      try {
        window.tap('trial', user?.company.stripeCustomerId);
      } catch (error) {
        console.log(error);
      }
    }
  }, [user?.company.stripeCustomerId, window.tap]);

  // on user change, we need to refetch the websocket token and terminate the connection
  // so it can be re-established with the new token
  useEffect(() => {
    const fetchSecureToken = async () => {
      const response = await fetch('/api/websockettoken', {
        credentials: 'include', // Include cookies in the request
      });
      const data = (await response.json()) as { token: string };
      sessionStorage.setItem('websocketToken', data.token);
    };
    fetchSecureToken().then(() => {
      wsClient?.terminate();
    });
  }, [user?.id]);

  return <UserContext.Provider value={{ user: userWithMethods || user, userLoading }}>{children}</UserContext.Provider>;
}
// eslint-disable-next-line react-refresh/only-export-components -- required for context, will not hurt HMR
export function useUser() {
  return useContext(UserContext);
}
