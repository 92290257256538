import { useUser } from '@app/context/UserContext';
import { pickLatest } from '@app/utils/pickLatest';
import { Button } from '@components/ui';
import { Task } from '@dieterApi/task/useTaskQuery';
import { useTranslation } from 'react-i18next';
import { useDashboard } from '../../useDashboard';

interface Props {
  task: Task;
  onConfirm: () => void;
}

export function LinkProcessBody({ task, onConfirm }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();

  const dashboard = useDashboard();

  const linkedUserApplication = user?.topics
    .flatMap((t) => t.applications)
    .find((a) => a.id === task.linkedApplication?.id);
  const existingQuestionnaire = pickLatest(linkedUserApplication?.questionnaires);

  const handleClick = () => {
    if (linkedUserApplication) {
      if (existingQuestionnaire) {
        dashboard.open(existingQuestionnaire.id);
      } else {
        dashboard.create(linkedUserApplication.id);
      }
    }
    onConfirm();
  };

  return (
    <div className="flex flex-col gap-10">
      <div>{task.confirmationText}</div>
      {!task.done && (
        <div className="flex gap-10">
          <div className="flex-4" />
          <div className="flex-2">
            <Button onClick={handleClick} data-testid="button-finish-task" className="text-base">
              {existingQuestionnaire ? t('common.proceed') : t('common.start')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
