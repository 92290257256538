import { useUser } from '@app/context/UserContext';
import { AlertDialog, Button, Icon, Loading, Spinner } from '@components/ui';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import { useCallback, useMemo, useState } from 'react';

import { useDashboard } from '@app/routes/Dashboard/useDashboard';
import {
  User,
  UserQuestionnaireApplicationTopic,
  UserTopicStatusApplicationsQuestionnaires,
} from '@dieterApi/user/useUserQuery';
import { Link } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { Topics } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { getTopicStates } from '@app/utils/getTopicStates';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { useUserValueDeleteMutation } from '@dieterApi/user/useUserValueDeleteMutation';
import './incidents.sass';

interface IncidentData {
  index: string;
  description: string;
  questionnaire?: UserQuestionnaireApplicationTopic;
  createdAt?: string;
}

export interface IncidentTableMeta extends MUIDataTableMeta {
  rowData: [
    string, // index
    string, // description
    string | null, // createdAt
    UserQuestionnaireApplicationTopic | null,
  ];
}

interface Props {
  topic: UserTopicStatusApplicationsQuestionnaires;
  topicStates: ReturnType<typeof getTopicStates>;
}

export function Incidents({ topic, topicStates }: Props): JSX.Element {
  const { user } = useUser();
  const dashboard = useDashboard();
  const {
    navigation: { roadBlock },
  } = useNavigation();
  const blankEntry = { index: '', description: '' };
  const [newEntry, setNewEntry] = useImmer<IncidentData>(blankEntry);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<IncidentTableMeta | null>(null);
  const [activeQuestionnaire, setActiveQuestionnaire] = useState<UserQuestionnaireApplicationTopic | null>(null);
  const incidentData = useMemo(() => {
    const parsedData = extractIncidentData(user);
    return newEntry.index ? [...parsedData, newEntry] : parsedData;
  }, [user, newEntry]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [addUserValue] = useUserValueAddMutation();
  const [deleteUserValue] = useUserValueDeleteMutation();

  // when new input field on manual entry row is added, focus it
  const handleRef = useCallback<(node: HTMLInputElement) => void>((node) => {
    if (node !== null) {
      node.focus();
    } else {
      // clear focus
      window.focus();
      // document.activeElement?.blur();
    }
  }, []);

  const handleDelete = (tableMeta: IncidentTableMeta) => {
    const index = tableMeta.rowData?.[0] as string;
    if (index === 'new') return;
    const keys = ['INCIDENT_DESCRIPTION'];
    keys.forEach((key) => deleteUserValue({ variables: { key, index }, context: { userId: user?.id || '' } }));
  };

  const handleAdd = () => {
    addUserValue({
      variables: { key: 'INCIDENT_DESCRIPTION', value: 'Neuer Vorfall', createIndex: true },
      context: { userId: user?.id || '' },
    }).then((res) => {
      if (res.data?.addUserValue) {
        const app = user?.topics
          .find((t) => t.id === Topics.DSV)
          ?.applications.find((a) => a.id === 'app-dsv-incident');
        app && dashboard.create(app.id, undefined, true, res.data.addUserValue.index);
      }
    });
  };

  const handleUpdate = (incident: IncidentData, description: string) => {};

  const handleOpenQuestionnaire = (meta: IncidentTableMeta) => {
    dashboard.open(meta.rowData[3]!.id);
  };

  const handleShowContract = (
    meta: IncidentTableMeta,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: 'show' | 'download'
  ) => {
    if (topicStates.isLocked) {
      roadBlock.open(topic);
      return;
    }
    setActiveQuestionnaire(meta.rowData[3]!);
    setAnchorEl(e.currentTarget);
  };

  const handleDownload = (meta: IncidentTableMeta) => {
    if (topicStates.isLocked) {
      roadBlock.open(topic);
      return;
    }
    const description = meta.rowData[1];
    const createdAt = meta.rowData[2];
    const activeQuestionnaire = meta.rowData[3]!;
    dashboard.download(activeQuestionnaire?.id, `Datenschutzvorfall v. ${createdAt} - ${description}`);
  };

  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'index',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'description',
      label: 'Beschreibung',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData?.[0];
          const isNew = tableMeta.rowData?.[0] === 'new';
          const incident = incidentData.find((i) => i.index === index);
          const originalValue = incident?.description || '';
          return (
            <div>
              <input
                ref={isNew ? handleRef : null}
                placeholder="Kurzbeschreibung"
                className="w-full bg-transparent text-sm"
                type="text"
                value={value}
                onChange={(e) => {
                  updateValue(e.target.value);
                }}
                onBlur={(e) => {
                  if (isNew) {
                    // delay to allow for click on save button
                    setTimeout(() => {
                      setNewEntry(blankEntry);
                    }, 300);
                  } else {
                    updateValue(originalValue);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (isNew) {
                      // handleSave(value);
                    } else {
                      handleUpdate(incident!, value);
                    }
                  }
                  // blur on escape
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                  }
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Erfasst am',
      options: {
        customBodyRender: (value) => {
          return value ? new Date(value).toLocaleDateString() : '';
        },
      },
    },
    {
      name: 'questionnaire',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'actions',
      label: 'Aktionen',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const meta = tableMeta as IncidentTableMeta;
          const index = meta.rowData[0];
          const questionnaire = meta.rowData[3];
          const isNotFinished = (questionnaire?.progress || 0) < 1;
          if (isNotFinished) {
            return (
              <Link to="#" onClick={() => handleOpenQuestionnaire(meta)}>
                Fortsetzen
              </Link>
            );
          } else {
            return (
              <>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Dokumente anzeigen">
                  <IconButton size="small" onClick={(e) => handleShowContract(meta, e, 'show')}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {dashboard.affects(undefined, questionnaire?.id) ? (
                  <Spinner size="small" />
                ) : (
                  <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Dokumente herunterladen">
                    <IconButton size="small" onClick={(e) => handleDownload(meta)}>
                      <Icon type="doc-16" size={16} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Vorfall bearbeiten">
                  <IconButton size="small" onClick={(e) => handleOpenQuestionnaire(meta)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            );
          }
        },
        customHeadLabelRender: () => '',
      },
    },
    {
      name: 'delete',
      label: 'Löschen',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const meta = tableMeta as IncidentTableMeta;
          const index = meta.rowData[0];
          const isNew = index === 'new';

          return (
            <div className="dtIncidentTable__action">
              <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={isNew ? 'Speichern' : 'Vorfall löschen'}>
                <IconButton
                  className="dtIncidentTable__deleteButton"
                  size="small"
                  onClick={() => {
                    if (isNew) {
                      // handleSave(description);
                    } else {
                      setDeleteRow(meta);
                      setDeleteAlertOpen(true);
                    }
                  }}
                  data-testid={`buton-action-incidents-${meta.rowIndex}`}
                >
                  {isNew ? <SaveIcon fontSize="small" /> : <DeleteIcon fontSize="small" />}
                </IconButton>
              </Tooltip>
            </div>
          );
        },
        customHeadLabelRender: () => '',
      },
    },
  ];

  const options: MUIDataTableOptions = {
    sortOrder: {
      name: 'name',
      direction: 'asc',
    },
    download: false,
    print: false,
    elevation: 0,
    // onRowClick: handleClick,
    selectableRows: 'none',
    selectableRowsHeader: false,
    // selectableRowsOnClick: true,

    customToolbar: () => (
      <Button className="text-base mb-3" onClick={handleAdd} icon={<AddIcon />} disabled={user?.isReadOnly}>
        Neuen Vorfall erfassen
      </Button>
    ),
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    textLabels: {
      selectedRows: {
        text: 'Zeilen ausgewählt',
        delete: 'Löschen',
        deleteAria: 'Ausgewählte Zeilen löschen',
      },
      body: {
        noMatch: 'Keine Einträge vorhanden',
      },
    },
  };

  return (
    <div className="dtIncidents">
      {incidentData ? (
        <MUIDataTable columns={columns} data={incidentData} title="Deine Vorfälle" options={options} />
      ) : (
        <Loading />
      )}
      <AlertDialog
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        message={
          <>
            Möchtest du den Vorfall <b>{deleteRow?.rowData[1]}</b> wirklich löschen?
          </>
        }
        onAccept={() => deleteRow && handleDelete(deleteRow)}
      />
      <DocumentMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        questionnaire={activeQuestionnaire}
      />
    </div>
  );
}

interface DocumentMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  questionnaire: UserQuestionnaireApplicationTopic | null;
}

export const DocumentMenu = ({ open, anchorEl, handleClose, questionnaire }: DocumentMenuProps): JSX.Element => {
  const documentLabels = questionnaire?.application.documentLabels;
  const { setNavigation } = useNavigation();

  const handleClick = (documentLabel: string) => {
    const index = documentLabels ? documentLabels.findIndex((label) => label === documentLabel) : -1;

    if (index < 0) return;
    questionnaire &&
      setNavigation((nav) => {
        nav.documentPaper = {
          modalOpen: true,
          questionnaire,
          index,
        };
      });
  };

  return (
    <>
      {' '}
      <Menu
        className="dtAccountMenu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {documentLabels?.map((dl) => (
          <MenuItem key={dl} onClick={() => handleClick(dl)}>
            {dl}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const extractIncidentData = (user: User | undefined): IncidentData[] => {
  if (!user) return [];
  const incidentData: IncidentData[] = [];
  user.userValues.forEach((userValue) => {
    if (userValue.key === 'INCIDENT_DESCRIPTION') {
      const description = userValue.value;
      // const createdAt = user.userValues.find((uv) => uv.key === 'INCIDENT_DATE' && uv.index === userValue.index)?.value;
      const questionnaire = user.questionnaires?.find((q) => q.userValueIndex === userValue.index);
      incidentData.push({
        description,
        index: userValue.index,
        questionnaire,
        createdAt: userValue.createdAt,
      });
    }
  });
  // only return partners where contractType is filled and which has not None
  return incidentData;
};
