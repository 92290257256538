import { gql, useMutation } from '@apollo/client';
import { Locales } from '@components/block/Header/LangSelect';

const UPDATE_USER_LOCALE = gql`
  mutation UpdateUserLocale($locale: String!) {
    updateUserLocale(locale: $locale)
  }
`;

export interface UpdateUserLocaleResult {
  updateUserLocale: Locales;
}

export interface UpdateUserLocaleInput {
  locale: Locales;
}

export function useUserUpdateLocaleMutation() {
  return useMutation<UpdateUserLocaleResult, UpdateUserLocaleInput>(UPDATE_USER_LOCALE, {
    refetchQueries: ['GetUser', 'questionnaireDoc', 'questionnaire'],
  });
}
