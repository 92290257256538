import { useUser } from '@app/context/UserContext';
import { AlertDialog, Spinner } from '@components/ui';
import { UserValue } from '@dieterApi/user/useUserQuery';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import cx from 'classnames';

import { useUserUpdateCompanyMutation } from '@dieterApi/user/useUserUpdateCompanyMutation';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { useUserValueDetailsQuery } from '@dieterApi/user/useUserValueDetailsQuery';
import SaveIcon from '@mui/icons-material/Save';
import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function useUserValuesToTranslatedEdit(): Record<string, string> {
  const { t } = useTranslation();

  return {
    COMPANY_NAME: t('route.user-config.user-value.company-name', 'Firmenname'),
    // EMAIL_SIGNUP: 'Kontakt E-Mail',
    ADDRESS: t('route.user-config.user-value.address', 'Adresse'),
    STATE: t('route.user-config.user-value.state', 'Bundesland'),
    LEGAL_FORM: t('route.user-config.user-value.legal-form', 'Rechtsform'),
    COMPANY_FORM: t('route.user-config.user-value.company-form', 'Unternehmensform'),
    INDUSTRY: t('route.user-config.user-value.industry', 'Branche'),
    COMPANY_SIZE: t('route.user-config.user-value.company-size', 'Unternehmensgröße'),
    COMPANY_GERMANY: t('route.user-config.user-value.company-germany', 'Sitz in Deutschland?'),
    COMPANY_COUNCIL: t('route.user-config.user-value.company-council', 'Betriebsrat existiert?'),
    DPO_EXISTS: t('route.user-config.user-value.dpo-exists', 'Datenschutzbeauftragter vorhanden?'),
    DPO_EXTERNAL_NAME: t('route.user-config.user-value.dpo-external-name', 'Name des externen Datenschutzbeauftragten'),
    DPO_EXTERNAL_EMAIL: t(
      'route.user-config.user-value.dpo-external-email',
      'E-Mail des externen Datenschutzbeauftragten'
    ),
    DPO_EXTERNAL_ADDRESS: t(
      'route.user-config.user-value.dpo-external-address',
      'Adresse des externen Datenschutzbeauftragten'
    ),
    VIDEO_SURVEILLANCE_EXISTS: t('route.user-config.user-value.video-surveillance-exists', 'Videoüberwachung?'),
  };
}

export function UserConfiguration() {
  const { t } = useTranslation();
  const { user } = useUser();
  const allUserValues = user?.userValues;
  const UserValuesToEdit = useUserValuesToTranslatedEdit();
  const relevantUserValues = allUserValues?.filter((userValue) => UserValuesToEdit[userValue.key]);
  const [msg, setMsg] = useState<{ text: string; type: 'success' | 'danger' }>({ text: '', type: 'success' });
  //sort the relevantUserValues by the order of the keys in UserValuesToEdit
  relevantUserValues?.sort((a, b) => {
    const aIndex = Object.keys(UserValuesToEdit).indexOf(a.key);
    const bIndex = Object.keys(UserValuesToEdit).indexOf(b.key);
    return aIndex - bIndex;
  });

  return (
    <div className="2xl:container  flex flex-col p-6">
      <h1>{t('route.user-config.header')}</h1>
      <p>{t('route.user-config.description')}</p>

      <div className="min-h-[40px] flex justify-between">
        <div className="flex gap-3">
          {msg.text && (
            <>
              {msg.type === 'danger' ? (
                <CancelIcon className="text-danger" />
              ) : (
                <CheckCircleOutlineIcon className="text-success" />
              )}
              <div className={cx(msg.type === 'danger' ? 'text-danger' : 'text-success')}>{msg.text}</div>
            </>
          )}
        </div>
        <div>
          <Link to="/dashboard">{t('common.action.back', 'Zurück')}</Link>
        </div>
      </div>
      {relevantUserValues?.map((userValue) => (
        <UserValueEditor
          userValue={userValue}
          label={UserValuesToEdit[userValue.key]}
          key={userValue.key}
          setMsg={setMsg}
        />
      ))}
    </div>
  );
}

interface Props {
  userValue: UserValue;
  label: string;
  setMsg: Dispatch<SetStateAction<{ text: string; type: 'success' | 'danger' }>>;
}
function UserValueEditor({ userValue, label, setMsg }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { data, loading } = useUserValueDetailsQuery(userValue.key);
  const [updateValue, { loading: updateValueLoading }] = useUserValueAddMutation();
  const [updateCompany, { loading: updateCompanyLoading }] = useUserUpdateCompanyMutation();
  const userValueDetails = data?.getUserValueDetails;
  const affectedQuestionnaires = user?.questionnaires?.filter(
    (q) => userValueDetails?.affectedQuestionnaireIds.includes(q.id)
  );

  const [alertOpen, setAlertOpen] = useState(false);

  // Local state for TextField or Select value
  const [value, setValue] = useState(userValue.value || '');
  const [hasChanged, setHasChanged] = useState(false);

  const askPermission = (value: string) => {
    setValue(value);
    setAlertOpen(true);
  };

  const handleChange = (value: string) => {
    // Update the local state
    setValue(value);

    setMsg({ text: '', type: 'success' });

    updateValue({
      variables: {
        key: userValue.key,
        value: value as string,
      },
    })
      .then(() => {
        setHasChanged(false);
        setMsg({
          text: t('route.user-config.message.save-changes-success', 'Änderungen gespeichert'),
          type: 'success',
        });
      })
      .catch(() => {
        setMsg({
          text: t('route.user-config.message.save-changes-error', 'Änderungen konnten nicht gespeichert werden'),
          type: 'danger',
        });
      });

    if (userValue.key === 'COMPANY_NAME') {
      updateCompany({
        variables: {
          companyName: value as string,
        },
      })
        .then(() => {
          setHasChanged(false);
          setMsg({
            text: t('route.user-config.message.save-changes-success', 'Änderungen gespeichert'),
            type: 'success',
          });
        })
        .catch(() => {
          setMsg({
            text: t('route.user-config.message.save-changes-error', 'Änderungen konnten nicht gespeichert werden'),
            type: 'danger',
          });
        });
    }

    setTimeout(() => {
      setMsg({ text: '', type: 'success' });
    }, 5000);
  };

  const handleTextChange = (value: string) => {
    setValue(value);
    setHasChanged(true);
  };

  const isMultiLine = userValueDetails?.type === 'MultilineText';

  return (
    <div className="flex flex-col md:flex-row w-full mb-4 md:items-center">
      <div className="md:w-1/3 mb-2 md:mb-0">
        <div>{label}</div>
      </div>
      <div className="md:w-2/3">
        {loading ? (
          <Skeleton />
        ) : userValueDetails?.type === 'SelectOne' ? (
          <Select className="w-full" value={value} onChange={(event) => askPermission(event?.target.value)}>
            {userValueDetails?.options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {userValueDetails.optionsLocalized[index]}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <OutlinedInput
            className="w-full"
            value={value}
            multiline={isMultiLine}
            onChange={(event) => handleTextChange(event?.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !isMultiLine && hasChanged) {
                handleChange(value);
              }
            }}
            endAdornment={
              hasChanged &&
              (updateCompanyLoading || updateValueLoading ? (
                <Spinner size="small" />
              ) : (
                <InputAdornment position="end">
                  <IconButton aria-label="save value" onClick={() => handleChange(value)} edge="end">
                    <SaveIcon />
                  </IconButton>
                </InputAdornment>
              ))
            }
          />
        )}
      </div>
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        message={
          <div>
            <Trans t={t} i18nKey="route.user-config.change-alert-dialog-message" values={{ label, value }}>
              Die Änderung von{' '}
              <strong>
                {{
                  /* @ts-expect-error error */
                  label: label,
                }}
              </strong>{' '}
              auf{' '}
              <strong>
                {{
                  /* @ts-expect-error error */
                  value: value,
                }}
              </strong>{' '}
              hat Auswirkungen auf die folgenden Fragebögen:
            </Trans>
            <ul className="list-disc list-outside font-semibold mt-2">
              {affectedQuestionnaires?.map((q) => <li key={q.id}>{q.application.title}</li>)}
            </ul>
            {t('route.user-config.change-alert-dialog-message2')}
          </div>
        }
        acceptText={t('common.action.change', 'Ändern')}
        onAccept={() => handleChange(value)}
        onCancel={() => setValue(userValue.value || '')}
      />
    </div>
  );
}
