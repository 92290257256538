import { gql, useMutation } from '@apollo/client';
import { FILE_FIELDS, User, UserFile } from '@dieterApi/user/useUserQuery';

const DELETE_FILE = gql`
  ${FILE_FIELDS}
  mutation DeleteFile($id: String!) {
    deleteFile(id: $id) {
      ...UserFileFields
    }
  }
`;

export interface FileDeleteInput {
  id: string;
}

export interface FileDeleteResult {
  deleteFile: UserFile;
}

export function useFileDeleteMutation() {
  return useMutation<FileDeleteResult, FileDeleteInput>(DELETE_FILE, {
    optimisticResponse: (data) => {
      return {
        deleteFile: {
          id: data.id,
          name: '',
          size: 0,
          uri: '',
          url: '',
          __typename: 'File',
        },
      };
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          getUser(existingUser: User) {
            const newUser = {
              ...existingUser,
              topics: existingUser.topics?.map((topic) => {
                return {
                  ...topic,
                  files: topic.files.filter((file) => file.id !== data?.deleteFile.id),
                };
              }),
            };
            return newUser;
          },
        },
      });
    },
  });
}
