import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { useTags } from '@app/hooks/useTags';
import { FileItem, FileUpload } from '@components/ui';
import { Button } from '@components/ui/Button/Button';
import { Markdown } from '@components/ui/Markdown/Markdown';
import { Shortcut } from '@components/ui/Shortcut/Shortcut';
import pDebounce from 'p-debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
// import { PopupButton } from 'react-calendly';
import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { Locales } from '@components/block/Header/LangSelect';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { MessageStep as Step } from '../../utils';
import { Props as BaseProps } from '../Step';
import './quest-step-message.sass';

export type Props = BaseProps<Step>;

export function MessageStep(props: Props) {
  const { t, i18n } = useTranslation();
  const { user } = useUser();

  const { steps, step, stepIndex } = props;
  const { question } = step;
  const {
    navigation: { isMobile },
    setNavigation,
  } = useNavigation();
  const { jwt } = useQuestionaireRoute();

  const [isAnswered, setAnswered] = useState(false);
  const [nextButtonWidth, setNextButtonWidth] = useState(1);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const isLastStep = steps[steps.length - 1] === step;
  const isFirstStep = steps[0] === step;

  const [tagAction] = useTags();
  const { enqueueSnackbar } = useSnackbar();

  const tags = props.step.question.tags;
  const isCalendly = tags.process.some((tag) => tag === 'CALENDLY');
  const isUpload = tags.process.some((tag) => tag === 'UPLOAD');
  const isAbort = tags.process.some((tag) => tag === 'ABORT');

  // per convention the title of the subsection corresponds to the topic for which files should be uploaded
  // TODO: This only works in the german language, will break in any other
  const uploadTopic = user?.topics.find((topic) => topic.titleLocales.de === step.parents[1]?.title);

  const confirmText = question.customerSpecificMetadata?.confirmText;

  async function onNext() {
    // we run all tag actions and only proceed on success
    tagAction(tags)
      .then(async (proceed) => {
        if (proceed) {
          setAnswered(true);
          props
            .onUpdateQuestion(
              question._id,
              question.options[0].value,
              true,
              jwt,
              jwt ? (i18n.language.slice(0, 2) as Locales) : undefined
            )
            .then(() => {
              // show "saved" animation
              setNavigation((nav) => void (nav.showSavedAnimation = true));
            });
          props.onAdvance(props.step.id);
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
      });
  }

  const debouncedOnNext = useMemo(() => {
    return pDebounce(onNext, 500, { before: true });
  }, [onNext]);

  // this removes the blur effect on the bottom when user scrolls to the bottom
  useEffect(() => {
    const onScroll = (e: Event) => {
      if (window.innerHeight + window.scrollY + 1 >= document.body.offsetHeight) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const nextButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // check if the text in nextButton spans over more than 2 lines, and if so then expand the

    if (nextButtonRef.current) {
      const { offsetHeight } = nextButtonRef.current;
      // get computed style of the element
      const style = getComputedStyle(nextButtonRef.current);
      // calculate number of lines by dividing the height by the line-height and rounding down
      const lines = Math.floor(offsetHeight / parseInt(style.lineHeight, 10));
      if (lines > 2) {
        setNextButtonWidth(nextButtonWidth + 2);
      }
    }
  }, [nextButtonRef.current]);

  const nextButton = (
    <Button
      className={cx({ transparencyBlock: !isScrolledToBottom })}
      disabled={isAnswered}
      onClick={onNext}
      data-testid="button-next"
      ref={nextButtonRef}
      googleTriggerClassName={isLastStep ? 'ga4-checkup-finished' : 'ga4-checkup-step'}
    >
      {confirmText ||
        (isFirstStep
          ? t('route.docs.step_next_button_isFirst')
          : isLastStep || isAbort
            ? t('route.docs.step_next_button_isLast')
            : t('common.continue'))}
    </Button>
  );

  return (
    <div className="dtQuestStep__message">
      {/* <StepInfo step={props.step} /> */}

      <div className="dtQuestStep__messageBody flex md:gap-5 flex-col md:flex-row gap-8">
        <div className="flex-1 hidden md:block">
          <img className="dtQuestStep__messageImage" src="/assets/images/dieter_kopf.svg" />
        </div>
        <div className="flex-4">
          <Markdown
            className="dtQuestStep__messageBalloon questionTitle"
            text={question.questionText}
            data-google={stepIndex}
          />
          {question.description ? <Markdown className="mt-20" text={question.description} /> : null}

          {isUpload && uploadTopic && (
            <div className="mt-20">
              <FileUpload topicId={uploadTopic.id} />
              {uploadTopic.files.length > 0 && (
                <Trans t={t} i18nKey="route.docs.hint_upload_topic_input" values={{ title: uploadTopic.title }}>
                  <p>
                    Bisher hochgeladene Dokumente für den Bereich{' '}
                    <strong>
                      {{
                        // @ts-expect-error
                        title: uploadTopic?.title,
                      }}
                    </strong>
                    :
                  </p>
                </Trans>
              )}
              {uploadTopic.files.map((file) => (
                <FileItem key={file.id} file={file} />
              ))}
            </div>
          )}
          <div className="flex gap-20 mt-20">
            {!isCalendly &&
              (isMobile ? (
                nextButton
              ) : (
                <Shortcut
                  disabled={isAnswered}
                  onAction={debouncedOnNext}
                  shortcutKey="Enter"
                  buttonWidth={nextButtonWidth}
                >
                  {nextButton}
                </Shortcut>
              ))}
          </div>
          {/* In case of external user, window can be closed after last step */}
          {isAnswered && jwt && isLastStep && (
            <div className="mt-5">
              <p className="text-primary-root">
                {t('route.docs.hint_last_step_close_window', '(Du kannst dieses Fenster jetzt schließen.)')}
              </p>
            </div>
          )}
        </div>
        <div className="flex-1 hidden md:block" />
      </div>
    </div>
  );
}
