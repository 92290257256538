// ApolloClientContext.js
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient, Client as WebsocketClient } from 'graphql-ws';
import { createContext, useContext, useMemo, useState } from 'react';
// apolloClient.js
import { split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import { ApolloClient, defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

import { typePolicies } from '@app/api/cachePolicies.ts';

const isDev = import.meta.env.MODE === 'development';

interface ApolloClientContext {
  isConnected: boolean;
  client: ApolloClient<any> | null;
  wsClient: WebsocketClient | null;
}

const ApolloClientContext = createContext<ApolloClientContext>({
  isConnected: false,
  client: null,
  wsClient: null,
});

export const ApolloClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [isConnected, setIsConnected] = useState(true);

  const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const wsUrl = `${wsProtocol}://${window.location.host}/socket`;

  const wsClient = useMemo(
    () =>
      createClient({
        url: wsUrl,
        shouldRetry: () => true,
        retryAttempts: 100,
        connectionParams: () => {
          const token = sessionStorage.getItem('websocketToken');
          return {
            headers: {
              'X-Websocket-Token': token,
            },
          };
        },

        on: {
          error: (error) => {
            console.log(error);
          },

          connected: () => {
            setIsConnected(true);
          },
          closed: () => {
            setIsConnected(false);
          },
          ping: () => {
            setIsConnected(true);
          },
        },
      }),
    []
  );

  const wsLink = useMemo(() => new GraphQLWsLink(wsClient), [wsClient]);

  const uploadLink = useMemo(
    () =>
      createUploadLink({
        uri: '/api/graphql',
      }),
    []
  );

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        const token = sessionStorage.getItem('token');
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      }),
    []
  );

  const link = useMemo(
    () =>
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        //@ts-ignore
        authLink.concat(uploadLink)
      ),
    [wsLink, authLink, uploadLink]
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        link,
        cache: new InMemoryCache({
          addTypename: true,
          typePolicies,
          dataIdFromObject(responseObject) {
            switch (responseObject.__typename) {
              case 'Tag':
                return `Tag:${responseObject.tag}`;
              default:
                return defaultDataIdFromObject(responseObject);
            }
          },
        }),
        connectToDevTools: isDev,
      }),
    [link]
  );
  return (
    <ApolloClientContext.Provider value={{ isConnected, client, wsClient }}>{children}</ApolloClientContext.Provider>
  );
};

// eslint-disable-next-line
export const useApolloClient = () => useContext(ApolloClientContext);
