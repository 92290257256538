// apolloClient.js
import { split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';

// HTTP connection for queries and mutations
const uploadLink = createUploadLink({
  uri: '/api/graphql',
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: 'ws://localhost:4000/socket',
    shouldRetry: () => true,
    retryAttempts: 100,
  })
);

// Authentication context for setting headers
const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Split links, directing queries and mutations to HTTP and subscriptions to WebSocket
export const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  //@ts-ignore
  authLink.concat(uploadLink)
);
