import client from '@app/client.ts';
import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { NavigationSection } from '@app/context/constants';
import { useFlow } from '@app/hooks/useFlow';
import { useNavigation } from '@app/hooks/useNavigation';
import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { Loading } from '@components/ui';

import { useQuestionnaireDocumentConfirmMutation } from '@dieterApi/questionnaire/useQuestionnaireDocumentConfirmMutation';
import { TaskType } from '@dieterApi/task/useTaskQuery';
import { useUserNotifySpecialCaseMutation } from '@dieterApi/user/useUserNotifySpecialCaseMutation';
import { GET_USER, GetUserResult } from '@dieterApi/user/useUserQuery';
import { IStateUpdateMutations } from '@legalosApi/hooks/useStateUpdateMutations';
import AllowedValueTypes from '@legalosApi/types/AllowedValueTypes';
import { IQuestionnaire } from '@legalosApi/types/IQuestionnaire';
import { ISection } from '@legalosApi/types/ISection';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import { FormControlLabel, Switch } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Chat } from '../Chat/Chat';
import { Locales } from '../Header/LangSelect';
import { Outline } from './Outline/Outline';
import { Stream } from './Stream/Stream';
import './quest.sass';
import { ChoiceStep, MessageStep, QuestionStep, toSteps } from './utils';

export type AdvanceCallback = (id: string, direction?: AdvanceDirection) => void;

export type UpdateQuestionCallback = (
  _id: string,
  value: AllowedValueTypes,
  immediate?: boolean,
  token?: string,
  locale?: Locales
) => Promise<void>;

export interface Props {
  /**
   * a flag indicating if a state update mutation (e.g. changing the calculation override status for a question) is currently in progress
   */
  isStateUpdateInProgress: boolean;

  /**
   * a flag indicating if an update mutation (even a debounced one) is currently in progress
   */
  isUpdateQuestionsInProgress: boolean;

  /**
   * updates the value of a single question in the Questionnaire
   */
  onUpdateQuestion: UpdateQuestionCallback;

  /**
   * The active questionnaire
   */
  questionnaire: IQuestionnaire;

  /**
   * a set of methods to mutate the state of a question (e.g. if a calculated value is overridden)
   */
  stateUpdateMutations: IStateUpdateMutations;

  /**
   * refetch the questionnaire
   */
  refetchQuestionnaire: () => void;
}

export type AdvanceDirection = 'forward' | 'backward';

export function Quest({
  isStateUpdateInProgress,
  isUpdateQuestionsInProgress,
  onUpdateQuestion,
  questionnaire,
  stateUpdateMutations,
  refetchQuestionnaire,
}: Props) {
  let sections: ISection[] = [];
  const { navigation, setNavigation, navigateDashboard } = useNavigation();
  const [confirmDocument] = useQuestionnaireDocumentConfirmMutation();
  const tasks = useTasks();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [flow, dispatchFlow] = useFlow();
  const [notifySpecial] = useUserNotifySpecialCaseMutation();
  const [advanceSchedule, setAdvanceSchedule] = useState<{
    origin: string;
    direction: AdvanceDirection;
  } | null>(null);
  const { t } = useTranslation();
  // we need a way of querying the questionnaire one last time before we leave the page, thats why we need this mutation
  // ...this is a rather dirty hack :( (but it works)
  // const [queryQuestionnaire] = useLegalOSMutation('questionnaireUpdate', {
  //   variables: {
  //     input: {
  //       questionnaireId: questionnaire._id,
  //       hasTableOfContents: questionnaire.hasTableOfContents,
  //     },
  //   },
  // });

  // extract question id from url and supply navigation callbacks
  const { currentParams, generatePath, navigateToPath, jwt } = useQuestionaireRoute();
  const { questionId, section, revisit, name } = currentParams;

  // state for skipping completed questions on revisit
  const [skipCompleted, setSkipCompleted] = useState(false);

  const userApp = user?.questionnaires?.find((q) => q.application.id === questionnaire.application.id)?.application;
  const sectionValid = section && section > 0 && section <= questionnaire.sections.length;
  const splitQuestionnaire = userApp?.splitApplication && sectionValid;

  if (splitQuestionnaire) {
    // on a split questionnaire we only show the sections of the current split
    sections = [questionnaire.sections[section - 1]];
  } else if (userApp?.id === 'app-dse-privacy-policy-create' && user?.isSubscriber) {
    // on the privacy policy questionnaire we show the second level of sections after a user has subscribed
    // to make navigation to each section easier
    sections = questionnaire.sections.flatMap((section) => section.children || []);
  } else {
    // otherwise we show all sections
    sections = questionnaire.sections;
  }

  const thisTask = tasks?.find(
    (t) =>
      t.application?.id === questionnaire.application.id &&
      t.type === TaskType.QUESTIONNAIRE &&
      (splitQuestionnaire ? t.section === section : true)
  );

  // we mark new questionnaire tasks as "not new" in the navigation state once they are opened
  useEffect(() => {
    thisTask?.isNew &&
      navigation?.newTasks[thisTask?.persistentId] &&
      setNavigation((nav) => void (nav.newTasks[thisTask?.persistentId] = false));
  }, [navigation.newTasks, thisTask]);

  useEffect(() => {
    thisTask && navigation.questTask.id !== thisTask.id && setNavigation((nav) => void (nav.questTask = thisTask));
  }, [thisTask]);

  // test for the existence of a section with "abbrechen" in the title
  // if so, then this will be the last step
  // TODO: Replace this with tag check
  // const lastSectionIndex = all.findIndex(({ title }) => title.indexOf('abbrechen') !== -1);

  // const sections = lastSectionIndex !== -1 ? all.slice(0, lastSectionIndex) : all;

  const steps = useMemo(() => toSteps(sections), [sections]);

  // when navigation to a section is requested from the outline
  // we move to the first question of the section
  const onSection = (section: ISection) => {
    const step = steps.find((step) => step.parents.some((parent) => parent._id === section._id));

    if (step) {
      navigateToPath({ questionId: step.id });
    }
  };

  // The following approach is necessary, because we need to make sure, that the "onAdvance" function
  // is always executed with the updated "steps" variable. Due to race conditions, this might not be the
  // case. Therefore we only "schedule" the advance call with the necessary information and execute it
  // once the steps variable has been updated.

  const onAdvance = (origin: string, direction: AdvanceDirection = 'forward') => {
    setAdvanceSchedule({ origin, direction });
  };

  // TODO: There is a problem here! At the end, the _onAdvance function is called twice, because the
  // useEffect is triggered twice. One time by advanceSchedule and one time by steps.
  // This leads to a visible effect for the user, because the notification snackbar is shown twice.
  useEffect(() => {
    if (advanceSchedule) {
      _onAdvance(advanceSchedule.origin, advanceSchedule.direction);
      setAdvanceSchedule(null);
    }
  }, [advanceSchedule]);

  // advance one question forward by looking up the question id in the steps
  const _onAdvance = useCallback(
    (origin: string, direction: AdvanceDirection = 'forward') => {
      // const { current: steps } = stepsRef;
      let index = steps.findIndex(({ id }) => id === origin);
      if (direction === 'forward') {
        if (index !== -1 && index < steps.length - 1) {
          window.scrollTo(0, 0);
          // skip AUTO steps and completed questions when revisiting
          while (
            (isCompletedAutoStep(steps[index + 1] as QuestionStep) ||
              (skipCompleted && (steps[index + 1] as QuestionStep).question?.state === QuestionnaireState.COMPLETE)) &&
            index < steps.length - 2 &&
            !jwt
          ) {
            index++;
          }

          // send custom event to google analytics, when halfway through the questionnaire
          if (window.dataLayer && index === Math.floor(steps.length / 2)) {
            window.dataLayer.push({
              event: 'ga4-event-questionnaire-midway',
              questionnaire: questionnaire.application.id,
            });
          }

          if (window._paq && index === Math.floor(steps.length / 2)) {
            window._paq.push(['trackEvent', 'funnel', 'questionnaire midway', questionnaire.application.title]);
          }

          navigateToPath({ questionId: steps[index + 1].id });
        } else if (index === steps.length - 1) {
          // send custom event to google analytics, when halfway through the questionnaire
          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'ga4-event-questionnaire-finished',
              questionnaire: questionnaire.application.id,
            });
          }

          if (jwt) {
            // this is the path for external users
            name &&
              dispatchFlow({
                type: 'add',
                callback: async () =>
                  confirmDocument({
                    variables: {
                      token: jwt,
                      name,
                      questionnaireId: questionnaire._id,
                    },
                  })
                    .then(() => {
                      enqueueSnackbar(
                        t(
                          'route.docs.snackbar_successfully_documented',
                          'Kenntnisnahme erfolgreich dokumentiert. Vielen Dank!'
                        ),
                        {
                          variant: 'success',
                        }
                      );
                      // push to some thank you page
                    })
                    .catch((e) =>
                      enqueueSnackbar(e.message, {
                        variant: 'error',
                        stack: e.stack,
                      })
                    ),
              });
          } else {
            // this handles finishing of the questionnaire
            // we execute all actions which have been queued up in the flowHandler
            // and redirect to root
            dispatchFlow({
              type: 'add',
              // this state change marks the period in which the flow is executing and the dashboard should not be mounted yet
              // TODO: solve this with an internal state of setNavigation
              callback: async () => setNavigation((nav) => void (nav.questionnaireWrapup = true)),
            });

            // we have to identify the task, which corresponds to this questionnaire.
            // Both task and questionnaire have a common application.
            // If the questionnaire is split into sections, we also have to match
            // the section to the task.
            const task = tasks?.find((task) => {
              if (splitQuestionnaire) {
                return task.application?.id === questionnaire.application.id && task.section == Number(section);
              } else {
                return task.application?.id === questionnaire.application.id;
              }
            });
            if (!task)
              console.log(
                `WARNING: Could not find task for questionnaire ${questionnaire.application}. Task will not be marked as DONE.`
              );

            dispatchFlow({
              type: 'add',
              callback: async () => task?.setStatus(true), // This sets the task in the Meta-Tree to DONE
            });

            dispatchFlow({
              type: 'add',
              callback: async () => await client.refetchQueries({ include: ['GetUser'] }),
            });

            dispatchFlow({
              type: 'add',
              callback: async () => setDocumentProgressFinished(questionnaire._id), // this will be the last action in the flow
              // making sure, that the document progress is set to 1 (even if due to race conditions
              // the getUser update would report something else).
            });

            dispatchFlow({
              type: 'add',
              callback: async () => {
                // // only after this questionnaire we want to navigate to the pricing section after finishing
                // task?.persistentId === 'questionnaire-ds-mgmt' && !user?.isSubscriber
                //   ? navigateDashboard(NavigationSection.Pricing)
                //   : // otherwise back to the topics
                navigateDashboard(
                  NavigationSection.Topic,
                  questionnaire.application.topic.id,
                  navigation.activeSubSection
                );
              },
            });

            // only after check-up send e-mail to team if special case
            if (task?.persistentId === 'questionnaire-check-up') {
              dispatchFlow({
                type: 'add',
                callback: async () => notifySpecial(),
              });
            }
            dispatchFlow({
              type: 'add',
              callback: async () =>
                setNavigation((nav) => {
                  nav.preferredTab = 'Documents';
                  nav.onboarding.inProgress = false;
                  nav.questionnaireWrapup = false;
                  nav.justFinishedQuestionnaireRemoteId = questionnaire._id;
                  nav.animateBadge = true;
                }),
            });

            // as a last step we want to query the questionnaire once more in order to make sure, that possible UserValues are
            // written to the database, when they were tagged on the last question. There is a race condition in the backend, which
            // might hinder this from happening, on the regular questionsUpdate query
            dispatchFlow({
              type: 'add',
              callback: async () => {
                const q = refetchQuestionnaire();
              },
            });

            dispatchFlow({
              type: 'add',
              callback: async () =>
                enqueueSnackbar(
                  t(
                    'route.docs.snackbar_questionnaire_completed',
                    `Der Fragebogen "${questionnaire.application.title}" wurde erfolgreich abgeschlossen.`,
                    { title: questionnaire.application.title }
                  ),
                  {
                    variant: 'success',
                  }
                ),
            });
          }

          // run the flow
          dispatchFlow({ type: 'execute' });
        }
      } else if (direction === 'backward') {
        if (index !== -1 && index > 0) {
          window.scrollTo(0, 0);
          // skip AUTO steps
          while (index > 0 && index !== -1 && isCompletedAutoStep(steps[index - 1] as QuestionStep)) {
            index--;
          }
          navigateToPath({ questionId: steps[index - 1].id });
        }
      }
    },
    [steps, tasks, section, jwt]
  );

  // stepIndex always reflects the question id from the url
  // if it cannot be found, we move to the first question
  const stepIndex = steps.findIndex(({ id }) => id === questionId);
  const step = steps[stepIndex] as ChoiceStep | QuestionStep | MessageStep | undefined; // exclude ListStep

  // mount and unmount effect on navigation component
  useEffect(() => {
    setNavigation((nav) => {
      nav.scope = jwt ? 'quest_external' : 'quest';
      nav.stepIndex = stepIndex;
      nav.steps = steps;
      nav.onAdvance = onAdvance;
      nav.activeSection = NavigationSection.Topic;
      nav.activeTopic = questionnaire.application.topic.id;
    });

    return () => {
      setNavigation((nav) => void (nav.scope = undefined));
    };
  }, [stepIndex, jwt]);

  // count the number of incomplete questions
  const nIncomplete = (steps as QuestionStep[]).filter(
    ({ question }) => question.state !== QuestionnaireState.COMPLETE
  ).length;

  // find the index of the last incomplete question
  const lastIncompleteIndex = (steps as QuestionStep[])
    .map(({ question: { state } }) => state)
    .findIndex((state) => state !== QuestionnaireState.COMPLETE);

  // when the skip completed flag is set, we directly forward to the next incomplete question
  useEffect(() => {
    if (!skipCompleted) return;
    const lastIncompleteStep = steps[lastIncompleteIndex > -1 ? lastIncompleteIndex : steps.length - 1];
    navigateToPath({ questionId: lastIncompleteStep.id });
  }, [skipCompleted]);

  if (stepIndex === -1) {
    // initially we redirect either to the first incomplete question (or the last if all are complete)
    // or to the first question if revisit is true
    const nextIndex = revisit ? 0 : lastIncompleteIndex > -1 ? lastIncompleteIndex : steps.length - 1;
    return (
      <Redirect
        to={generatePath({
          ...currentParams,
          questionId: steps[nextIndex].id,
        })}
      />
    );
  }

  if (!tasks && !jwt) {
    return <Loading />;
  }

  return (
    <>
      <div className="dtQuest container flex flex-row gap-20 flex-nowrap mx-auto px-7 relative">
        <Outline
          activeIds={steps[stepIndex].parents.map((section) => section._id)}
          onSection={onSection}
          sections={splitQuestionnaire ? sections[0].children || [] : sections}
          saveAndClose={!userApp?.topic.tags.map((tag) => tag.tag).includes('noresume') && !jwt}
          revisit={revisit || false}
        />
        <Stream
          questionnaire={questionnaire}
          steps={steps}
          stepIndex={stepIndex}
          onAdvance={onAdvance}
          onUpdateQuestion={onUpdateQuestion}
          revisit={revisit || false}
        />

        <div className="flex-2 md:block absolute md:relative top-0 w-full left-0">
          {revisit && (
            <RevisitArea nIncomplete={nIncomplete} skipCompleted={skipCompleted} setSkipCompleted={setSkipCompleted} />
          )}
        </div>
        <Chat />
      </div>
    </>
  );
}

const setDocumentProgressFinished = (questionnaireId: string) => {
  const { cache } = client;
  const result: GetUserResult | null = cache.readQuery({ query: GET_USER });
  const user = result?.getUser;
  if (user) {
    const thisQuestionnaire = user.questionnaires?.find((q) => q.questionnaireId === questionnaireId);
    cache.modify({
      id: `LocalQuestionnaire:${thisQuestionnaire?.id}`,
      fields: {
        progress: () => 1,
      },
    });
  }
};

const isCompletedAutoStep = (step: QuestionStep): boolean => {
  return (
    (step.question?.tags.process.includes('AUTO') && step.question?.state === QuestionnaireState.COMPLETE) || false
  );
};

function RevisitArea({
  nIncomplete,
  skipCompleted,
  setSkipCompleted,
}: {
  nIncomplete: number;
  skipCompleted: boolean;
  setSkipCompleted: (skip: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className=" flex flex-col md:p-7 p-2 border-dashed bg-white shadow-md md:rounded-md md:border border-slate-400 gap-20 text-sm md:text-base">
      <FormControlLabel
        control={<Switch value={skipCompleted} onChange={(e) => setSkipCompleted(e.target.checked)} />}
        label={t('route.docs.revisit_area_switch_label')}
      />
      <Trans t={t} i18nKey="route.docs.hint_unanswered_questions_count" values={{ count: nIncomplete }}>
        <div className="md:block hidden">
          Noch <span className="font-semibold py-0.5 px-1 text-slate-600 bg-slate-200 rounded-sm">{nIncomplete}</span>{' '}
          unbeantwortete Fragen
        </div>
      </Trans>
    </div>
  );
}
