import { gql, useMutation } from '@apollo/client';
import { User, USER_FIELDS } from './useUserQuery';

const SWITCH_TENANT = gql`
  ${USER_FIELDS}
  mutation SwitchTenant($companyId: String!) {
    switchTenant(companyId: $companyId) {
      ...UserFields
    }
  }
`;

export interface SwitchTenantInput {
  companyId: string;
}

export interface SwitchTenantResult {
  switchTenant: User;
}

export function useSwitchTenantMutation() {
  return useMutation<SwitchTenantResult, SwitchTenantInput>(SWITCH_TENANT, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: () => data?.switchTenant,
        },
      });
    },
  });
}
