import i18n from 'i18next';

export function useLocalizedTranslation(locale: string) {
  const localizedI18n = i18n.createInstance();
  localizedI18n.init({
    resources: {
      en: {
        translation: {
          usercentrics: '<0>Additional option:</0> Professional Cookie-Banner plus {{price}} EUR/month',
          customer_mode: {
            companies: 'For Companies',
            agencies: 'For Agencies',
            dpos: 'For Data Protection Officers',
          },
          customer_mode_short: {
            companies: 'Companies',
            agencies: 'Agencies',
            dpos: 'DPOs',
          },
          common: {
            currentplan: 'Your current plan',
            upgradeoption: 'Your upgrade option',
            recommendation: 'Our recommendation',
            upgrade_now: 'Upgrade now',
            book_now: 'Book now',
            try_now: 'Try 7 days for free',
            start_now: 'Start now',
            month: 'Month',
            period: 'Contract period 1 year',
            save: 'Save',
            monthly_billing: 'Monthly billing',
            yearly_billing: 'Yearly billing (save up to 50%)',
            instead: 'Instead of',
            always_free: 'Always free',
          },
          freemium: {
            description: 'A simple introduction to data protection',
            f1: 'Comprehensive GDPR analysis of the company',
            f2: 'Legally compliant management of existing documents',
            f3: 'General advice and recommendations for GDPR implementation',
            f4: 'Data protection training for one user',
            f5: 'Limited use of the Data Protection Management System',
          },
          ticket: {
            name: 'Single service',
            description: 'Book an individual service from the modular system',
            featureTitle: 'Everything from the Free Plan plus:',
            dse: {
              label: 'Privacy Policy & Legal Notice',
              description:
                'Legally compliant website through a customized privacy policy and integrated legal notice creation',
              f1: 'GDPR-compliant privacy policy',
              f2: 'Direct integration into the website via live API',
              f3: 'Legally secure legal notice',
              f4: 'Also ideal for webshops, blogs and social media appearances',
              f5: 'Suitable passages for ALL tracking or analysis tools',
              f6: 'Legal team adds passages for new services within one week',
              f7: 'Automated advice and action recommendations for legal changes',
            },
            toms: {
              label: 'TOMs & Authorization Concept',
              description: 'Simple and intuitive documentation of all measures for your company',
              f1: 'GDPR-compliant documentation of technical and organizational measures',
              f2: 'Easy creation of the authorization concept',
              f3: 'Automated advice and action recommendations for legal changes',
            },
            vvt: {
              label: 'Processing Directory & Deletion Concept',
              description: 'Combined creation of the processing directory and deletion concept',
              f1: 'GDPR-compliant creation of the processing directory',
              f2: 'GDPR-compliant creation of the deletion concept',
              f3: 'Automated advice and action recommendations for legal changes',
            },
            avv: {
              description: 'Efficient creation and management of data processing agreements',
              label: 'Data Processing (AVV)',
              f1: 'GDPR-compliant creation of data processing agreements (AVV)',
              f2: 'Automated generation of standard contractual clauses (SCCs)',
              f3: 'Creation and management of joint controlling agreements',
              f4: 'Legally secure adaptation to current data protection regulations',
              f5: 'Easy management of contracts through an intuitive user interface',
            },
          },
          basic: {
            description:
              'The complete data protection toolkit for all companies that do not need to officially appoint a data protection officer according to GDPR guidelines.',
            featureTitle: 'Everything from the Free Plan plus:',
            f1: 'Full use of the Data Protection Management System',
            f2: 'Creation of all GDPR documents',
            f3: 'Multi-user capability',
            f4: 'GDPR-compliant privacy policy and legal notice',
            f5: 'Establishment of necessary processes & specific action recommendations',
            f6: 'Basic data protection training for all employees & specialized training',
            f7: 'Legally compliant responses to requests from affected persons',
            f8: 'GDPR-compliant handling of data protection breaches',
            f9: 'Online Data Protection (Website, Social Media, etc.)',
            f10: 'Offline Data Protection (internal data processing, communication, etc.)',
            f11: 'Employee Data Protection (obligations, home office, application & employment, etc.)',
            f12: 'Contract partners (instructions, rights, etc.)',
            f13: 'Order processing (AVV)',
            f14: 'Technical and organizational measures (TOMs)',
            f15: 'Processing directory & deletion concept',
            f16: 'Video surveillance',
            f17: 'Data protection impact assessment',
          },
          premium: {
            description:
              'The complete data protection solution for all companies that are legally obliged to appoint a Data Protection Officer (DSB).',
            featureTitle: 'Everything from Basic plus:',
            f1: 'Appointment of an official Data Protection Officer (DSB)',
            f2: 'Personal review of the implementation of necessary measures',
            f3: 'Communication and collaboration with the competent supervisory authority',
            f4: 'Information and advice to the responsible persons in the company',
          },
          agency_basic: {
            description:
              'The comprehensive data protection package for agencies that not only want to ensure their own GDPR compliance, but also want to create GDPR-compliant privacy policies and imprints for up to 50 clients.',
            featureTitle: 'Everything from Basic for Companies - plus:',
            f1: 'Client management in the dashboard',
            f2: '50 GDPR-compliant individual privacy policies and imprints',
            f3: 'Consent banner powered by Usercentrics for all clients',
            f4: 'Live service via chat',
            f5: 'GDPR website scanner',
            f6: 'Multi-user capability',
            f7: 'Possible decoupling of client accounts for takeover by the client after project completion',
          },
          agency_premium: {
            description: 'Our agency model including a Data Protection Officer (DPO) for agencies',
            featureTitle: 'Everything from Agency Basic plus:',
            f1: 'Appointment of an official Data Protection Officer (DPO)',
            f2: 'Personal control of the implementation of necessary measures',
            f3: 'Communication and cooperation with the responsible supervisory authority',
            f4: 'Instruction and advice to the responsible persons in the company',
          },
          dpo_basic: {
            description: 'The comprehensive data protection package for data protection officers',
            featureTitle: 'Everything from Basic for Companies - plus:',
            f1: 'Client management in the dashboard',
            f2: 'Data protection-compliant work for your own company and for your customers',
            f3: '1 client with full functionality (analogous to "Basic for Companies") already included',
            f4: 'Additional clients can be flexibly added at any time (1, 5, 10 packages)',
            f5: 'Multi-user capability',
            f6: 'Live service via chat',
            f7: 'Possible decoupling of client accounts for takeover by the client after project completion',
          },
          tenants_select: {
            '0': '1 tenant included',
            '1': '+ 1 additional tenant',
            '5': '+ 5 additional tenants',
            '10': '+ 10 additional tenants',
          },
        },
      },
      de: {
        translation: {
          usercentrics: '<0>Zusatzoption:</0> Professioneller Cookie-Banner zzgl. {{price}} EUR/Monat',
          customer_mode: {
            companies: 'Für Firmen',
            agencies: 'Für Agenturen',
            dpos: 'Für Datenschutzbeauftragte',
          },
          customer_mode_short: {
            companies: 'Firmen',
            agencies: 'Agenturen',
            dpos: 'DSBs',
          },
          common: {
            currentplan: 'Dein aktueller Plan',
            upgradeoption: 'Deine Upgrade-Option',
            recommendation: 'Unsere Empfehlung',
            upgrade_now: 'Jetzt upgraden',
            book_now: 'Jetzt buchen',
            try_now: '7 Tage kostenlos testen',
            start_now: 'Sofort loslegen',
            month: 'Monat',
            period: 'Vertragslaufzeit 1 Jahr',
            save: 'Spare',
            monthly_billing: 'Monatliche Zahlweise',
            yearly_billing: 'Jährliche Zahlweise (spare bis zu 50%)',
            instead: 'Statt',
            always_free: 'Für immer kostenlos',
          },
          freemium: {
            description: 'Der einfache Einstieg in den Datenschutz',
            f1: 'Umfassende DSGVO-Analyse des Unternehmens',
            f2: 'Rechtssichere Verwaltung bestehender Dokumente',
            f3: 'Allgemeine Hinweise und Empfehlungen zur Umsetzung der DSGVO',
            f4: 'Datenschutzrechtliche Schulung eines Nutzers',
            f5: 'Eingeschränkte Nutzung des Datenschutz-Management-Systems',
          },
          ticket: {
            name: 'Einzelleistung',
            description: 'Buche eine Einzelleistung aus dem Baukasten',
            featureTitle: 'Alles aus dem Free Plan plus:',
            dse: {
              label: 'Datenschutzerklärung & Impressum',
              description:
                'Rechtssichere Website durch passgenaue Datenschutzerklärung und integrierte Impressumserstellung',
              f1: 'DSGVO-konforme Datenschutzerklärung',
              f2: 'Direkte Integration in die Website per Live-API',
              f3: 'Rechtssicheres Impressum',
              f4: 'Ideal auch für Webshops, Blogs und Social Media Auftritte',
              f5: 'Passende Passagen für ALLE Tracking- oder Analyse-Tools',
              f6: 'Juristenteam ergänzt Passagen für neue Services innerhalb einer Woche',
              f7: 'Automatisierte Hinweise und Handlungsempfehlungen bei rechtlichen Veränderungen',
            },
            toms: {
              description: 'Einfache und intuitive Dokumentation aller Maßnahmen für dein Unternehmen',
              label: 'TOMs & Berechtigungskonzept',
              f1: 'DSGVO-konforme Dokumentation der technischen und organisatorischen Maßnahmen',
              f2: 'Einfache Erstellung des Berechtigungskonzeptes',
              f3: 'Automatisierte Hinweise und Handlungsempfehlungen bei rechtlichen Veränderungen',
            },
            vvt: {
              description: 'Kombinierte Erstellung von Verzeichnis der Verarbeitungstätigkeiten (VVT) und Löschkonzept',
              label: 'Verarbeitungsverzeichnis & Löschkonzept',
              f1: 'DSGVO-konforme Erstellung des Verzeichnisses der Verarbeitungstätigkeiten (VVT)',
              f2: 'DSGVO-konforme Erstellung des Löschkonzeptes',
              f3: 'Automatisierte Hinweise und Handlungsempfehlungen bei rechtlichen Veränderungen',
            },
            avv: {
              description: 'Effiziente Erstellung und Verwaltung von Auftragsverarbeitungsverträgen',
              label: 'Auftragsverarbeitung (AVV)',
              f1: 'DSGVO-konforme Erstellung von Auftragsverarbeitungsverträgen (AVV)',
              f2: 'Automatisierte Generierung von Standardvertragsklauseln (SCCs)',
              f3: 'Erstellung und Verwaltung von Joint-Controlling-Vereinbarungen',
              f4: 'Rechtssichere Anpassung an aktuelle Datenschutzbestimmungen',
              f5: 'Einfaches Management von Verträgen durch intuitive Nutzeroberfläche',
            },
          },
          basic: {
            description:
              'Der komplette Datenschutz-Baukasten für alle Unternehmen, die keinen offiziell bestellten Datenschutzbeauftragten nach den Vorgaben der DSGVO benötigen. ',
            featureTitle: 'Alles aus dem Free Plan plus:',
            f1: 'Umfassende Nutzung des Datenschutz-Management-Systems',
            f2: 'Erstellung aller DSGVO-Dokumente',
            f3: 'Multi-User Fähigkeit',
            f4: 'DSGVO-konforme Datenschutzerklärung und Impressum',
            f5: 'Etablierung der notwendigen Prozesse & konkrete Handlungsempfehlungen',
            f6: 'Datenschutzrechtliche Grundschulung aller Mitarbeiter & Spezialschulungen',
            f7: 'Rechtssichere Beantwortung von Betroffenenanfragen',
            f8: 'DSGVO-konformer Umgang mit Datenschutzverletzungen',
            f9: 'Online Datenschutz (Website, Social Media, etc.)',
            f10: 'Offline Datenschutz (interne Datenverarbeitung, Kommunikation, etc.)',
            f11: 'Mitarbeiter Datenschutz (Verpflichtungen, Home-Office, Bewerbung & Anstellung, etc.)',
            f12: 'Vertragspartner (Belehrungen, Rechte, etc.)',
            f13: 'Auftragsverarbeitung (AVV)',
            f14: 'Technische und organisatorische Maßnahmen (TOMs)',
            f15: 'Verarbeitungsverzeichnis & Löschkonzept',
            f16: 'Videoüberwachung',
            f17: 'Datenschutzfolgeabschätzung',
          },
          agency_basic: {
            description:
              'Das umfassende Datenschutz-Komplettpaket für Agenturen, die nicht nur ihre eigene DSGVO-Konformität sicherstellen, sondern auch für bis zu 50 Mandanten DSGVO-konforme Datenschutzerklärungen und Impressum erstellen möchten.',
            featureTitle: 'Alles aus Basic für Unternehmen - plus:',
            f1: 'Mandantenverwaltung im Dashboard',
            f2: '50 DSGVO-konforme individuelle Datenschutzerklärungen und Impressum',
            f3: 'Consent-Banner powered by Usercentrics für alle Mandanten',
            f4: 'Live-Service per Chat',
            f5: 'DSGVO Website Scanner',
            f6: 'Multi-User Fähigkeit',
            f7: 'Mögliche Abkopplung der Mandantenaccounts zur Übernahme durch den Mandanten nach Projektende',
          },
          premium: {
            description:
              'Die Datenschutz-Komplettlösung für alle Unternehmen, die gesetzlich verpflichtet sind einen Datenschutzbeauftragten (DSB) zu bestellen. ',
            featureTitle: 'Alles aus Basic plus:',
            f1: 'Bestellung eines offiziellen Datenschutzbeauftragten (DSB)',
            f2: 'Persönliche Kontrolle der Umsetzung notwendiger Maßnahmen',
            f3: 'Kommunikation und Zusammenarbeit mit der zuständigen Aufsichtsbehörde',
            f4: 'Unterrichtung und Beratung der verantwortlichen Personen im Unternehmen',
          },
          agency_premium: {
            description: 'Unser Agenturmodell inklusive Datenschutzbeauftragten (DSB) für Agenturen',
            featureTitle: 'Alles aus Agency Basic plus:',
            f1: 'Bestellung eines offiziellen Datenschutzbeauftragten (DSB)',
            f2: 'Persönliche Kontrolle der Umsetzung notwendiger Maßnahmen',
            f3: 'Kommunikation und Zusammenarbeit mit der zuständigen Aufsichtsbehörde',
            f4: 'Unterrichtung und Beratung der verantwortlichen Personen im Unternehmen',
          },
          dpo_basic: {
            description: 'Das umfassende Datenschutz-Komplettpaket für Datenschutzbeauftragte',
            featureTitle: 'Alles aus Basic für Unternehmen - plus:',
            f1: 'Mandantenverwaltung im Dashboard',
            f2: 'Datenschutzkonformes Arbeiten für das eigene Unternehmen und für deine Kunden',
            f3: '1 Mandant mit vollem Funktionsumfang (analog "Basic für Unternehmen") bereits inklusive',
            f4: 'Zusätzliche Mandanten können zu jedem Zeitpunkt flexibel (1er, 5er, 10er Pakete) hinzugebucht werden',
            f5: 'Multi-User Fähigkeit',
            f6: 'Live-Service per Chat',
            f7: 'Mögliche Abkopplung der Mandantenaccounts zur Übernahme durch den Mandanten nach Projektende',
          },
          tenants_select: {
            '0': '1 Mandant inklusive',
            '1': '+ 1 zusätzlicher Mandant',
            '5': '+ 5 zusätzliche Mandanten',
            '10': '+ 10 zusätzliche Mandanten',
          },
        },
      },
    },
    lng: locale, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: 'de', // use en if detected lng is not available
  });

  return localizedI18n;
}
