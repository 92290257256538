import { IntervalValues } from '@app/routes/Pricing/Pricing';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Trans } from 'react-i18next';
import { useLocalizedTranslation } from './Translation';

const USERCENTRICS_PRICE = {
  month: 8,
  year: 5,
};

export function UserCentricsFeature({
  onCheck,
  checked,
  interval,
  locale,
}: {
  onCheck: (val: boolean) => void;
  checked: boolean;
  interval: IntervalValues;
  locale: string;
}) {
  const { t } = useLocalizedTranslation(locale);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(event.target.checked);
  };

  const price = USERCENTRICS_PRICE[interval];

  return (
    <div>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} name="usercentrics-option" color="primary" />}
        label={
          <span className="text-sm ">
            {/* @ts-ignore */}
            <Trans t={t} i18nKey={'usercentrics'} values={{ price }}>
              <strong>Zusatzoption:</strong> Professioneller Cookie-Banner zzgl. {price} EUR/Monat
            </Trans>
            <span className="text-gray-400 text-xs"> (powered by Usercentrics, max. 50.000 Sessions / Monat )</span>
          </span>
        }
      />
    </div>
  );
}
