import { NavigationMobile } from '@components/block/Navigation/NavigationMobile';
import { Swap } from '@components/ui';
import { IQuestionnaire } from '@legalosApi/types/IQuestionnaire';
import cx from 'classnames';
import { AdvanceCallback, UpdateQuestionCallback } from '../Quest';
import { Step } from '../Step/Step';
import { AnyStep } from '../utils';
import './quest-stream.sass';

export interface Props {
  onAdvance: AdvanceCallback;
  onUpdateQuestion: UpdateQuestionCallback;
  questionnaire: IQuestionnaire;
  stepIndex: number;
  steps: Array<AnyStep>;
  revisit: boolean;
}

export function Stream(props: Props) {
  const { stepIndex, steps, revisit } = props;
  const step: AnyStep | undefined = steps[stepIndex];

  return (
    <Swap
      className={cx('md:grow-[6] max-md:flex-1 md:basis-0 relative md:mt-0 mb-32 pr-4 md:pr-0', {
        'mt-[40px]': revisit,
      })}
      pageClassName="dtQuestStream__page"
      uri={step ? step.id : '@empty'}
    >
      <>
        {step ? <Step {...props} step={step} /> : null}
        <NavigationMobile />
      </>
    </Swap>
  );
}
