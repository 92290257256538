import { useApolloClient } from '@app/context/ApolloClientContext';
import { NavigationScope } from '@app/context/NavigationContext';
import { useUser } from '@app/context/UserContext';
import { OnboardingApps } from '@app/context/constants';
import useChat from '@app/hooks/useChat';
import { useNavigation } from '@app/hooks/useNavigation';
import { Spinner } from '@components/ui';
import { CloseOutlined } from '@mui/icons-material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendIcon from '@mui/icons-material/Send';
import { Backdrop, IconButton, Tooltip } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import LangSelect from '../Header/LangSelect';
import { Message } from './Message';
import { ChatTokenType } from './constants';
import dieter_kopf from '/assets/images/dieter_kopf.svg';

let interval: any;
let last_scroll_top: number;

interface Props {
  chatId: string;
}

interface ISuggestion {
  question: string;
  description: string;
}

export function Chat() {
  const { user } = useUser();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const { isConnected } = useApolloClient();
  const history = useHistory();
  const {
    navigation: { isMobile, scope, hubspot },
    setNavigation,
  } = useNavigation();

  const isQuestScope = scope === 'quest';
  const isOnboardingScope = scope === 'onboarding';

  const baseSuggestions: ISuggestion[] = [
    {
      question: t('components.block.chat.suggestions.s1.question'),
      description: t('components.block.chat.suggestions.s1.desc'),
    },
    {
      question: t('components.block.chat.suggestions.s2.question'),
      description: t('components.block.chat.suggestions.s2.desc'),
    },
    {
      question: t('components.block.chat.suggestions.s3.question'),
      description: t('components.block.chat.suggestions.s3.desc'),
    },
  ];

  const SUGGESTIONS: Record<NavigationScope, ISuggestion[]> = {
    dashboard: baseSuggestions,
    quest_external: baseSuggestions,
    quest: [
      ...baseSuggestions,
      {
        question: t('components.block.chat.suggestions.s4.question'),
        description: t('components.block.chat.suggestions.s4.desc'),
      },
    ],
    onboarding: [
      {
        question: t('components.block.chat.suggestions.onboarding.dse.question'),
        description: t('components.block.chat.suggestions.onboarding.dse.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.av.question'),
        description: t('components.block.chat.suggestions.onboarding.av.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.toms.question'),
        description: t('components.block.chat.suggestions.onboarding.toms.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.vvt.question'),
        description: t('components.block.chat.suggestions.onboarding.vvt.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.checkup.question'),
        description: t('components.block.chat.suggestions.onboarding.checkup.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.unknown.question'),
        description: t('components.block.chat.suggestions.onboarding.unknown.desc'),
      },
    ],
  };

  const baseWelcomeMessage = t('components.block.chat.hello');
  const welcomeMessage: Record<NavigationScope, React.ReactNode> = {
    onboarding: (
      <div>
        <p>{t('components.block.chat.hello_onboarding.p1')} 👋</p> {t('components.block.chat.hello_onboarding.p2')}
      </div>
    ),
    dashboard: baseWelcomeMessage,
    quest: baseWelcomeMessage,
    quest_external: baseWelcomeMessage,
  };

  const hubspotWidgetActive = window.HubSpotConversations?.widget?.status()?.loaded || false;

  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const latestChat = user?.chats?.reduce((acc, chat) => (chat.createdAt > acc.createdAt ? chat : acc), user.chats[0]);

  const chat = useChat({
    chatId: latestChat?.id || '',
    handlers: {
      onMessageAdded: () => {
        const win = ref.current;

        win?.addEventListener('scroll', () => {
          if (win.scrollTop !== last_scroll_top) clearInterval(interval);
        });
      },
    },
  });

  const handleClick = () => {
    chat.addChat();
  };

  useEffect(() => {
    setIsExpanded(isOnboardingScope);
  }, [isOnboardingScope]);

  // if no chat exists, create a new one
  useEffect(() => {
    if (!latestChat) chat.addChat();
  }, [latestChat]);

  const vhToPixels = (vh: number) => {
    return Math.round(window.innerHeight * (vh / 100));
  };

  const vwToPixels = (vw: number) => {
    return Math.round(window.innerWidth * (vw / 100));
  };

  useEffect(() => {
    const win = ref.current;

    if (win && chat.isStreaming) {
      interval = setInterval(() => {
        win.scrollTo({ top: win.scrollHeight });
        last_scroll_top = win.scrollTop;
      }, 1);
    } else if (!chat.isStreaming && interval) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [chat.isStreaming]);

  const shouldDisableInput = chat.isStreaming || !chat.input.split('\n').filter(Boolean).length;

  const styles = useSpring({
    height: isExpanded ? vhToPixels(isMobile ? 95 : 80) : 80,
    // width: isExpanded ? vwToPixels(60) : vwToPixels(100),
    // backgroundColor: isExpanded ? 'rgba(255, 255, 255, 1)' : '',
    // bottom: isExpanded ? '20px' : '80px',
    // backdropFilter: isExpanded ? 'blur(10px)' : 'none',
  });

  const tokenActions: Record<ChatTokenType, () => void> = {
    [ChatTokenType.ContactEmployee]: () => {
      setNavigation((draft) => void (draft.hubspot.showChat = true));
      setIsExpanded(false);
      // if hubspot is already loaded, open the chat
      if (hubspotWidgetActive) {
        window.HubSpotConversations?.widget?.open();
      }
    },
    [ChatTokenType.OnboardingDSE]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.DSE }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingTOMs]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.TOMs }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingCheckup]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.CHECKUP }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingVVT]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.VVT }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingAVV]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.AVV }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingImprint]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.IMPRINT }));
      history.push('/dashboard');
      chat.addChat();
    },
  };

  const handleSend = () => {
    chat.submitMessage(chat.input);
    inputRef.current?.attributeStyleMap.set('height', '52px');
  };

  return (
    <>
      <animated.div
        style={styles}
        className={cx(
          'max-h-[95vh] max-w-[800px] fixed z-[1240] overflow-hidden rounded-t-2xl md:shadow-[0px_2px_6px_-1px_rgba(0,0,0,0.2)]',
          hubspotWidgetActive && !isExpanded
            ? 'w-[75vw] md:w-[85vw] lg:w-[95vw] left-0 lg:left-1/2 lg:-translate-x-1/2'
            : 'w-full left-1/2 -translate-x-1/2',

          isExpanded ? 'bg-white z-[1000000]' : isQuestScope ? ' bg-gray-100' : 'bg-primary-100',
          !isExpanded ? '-bottom-3 md:bottom-0 ' : 'bottom-0' //  border-gray-200
        )}
      >
        <div className={'w-full h-full flex flex-col absolute left-1/2 -translate-x-1/2'}>
          {/* <Button onClick={() => setNavigation((draft) => void (draft.showHubspotChat = true))}>HUBSPOT</Button> */}
          {isExpanded && (
            <>
              <header className="border-b border-gray-200 relative flex items-center space-x-3 bg-white px-6 py-5 rounded-lg">
                <div className="absolute top-0 right-0">
                  <IconButton onClick={() => setIsExpanded(false)}>
                    <CloseOutlined fontSize="large" />
                  </IconButton>
                </div>
                <div className="flex-shrink-0">
                  <img className="h-14 w-14 rounded-full" src={dieter_kopf} alt="Avatar of Dieter" />
                </div>

                <div className="min-w-0 ">
                  <span className="text-sm font-medium text-gray-900">Dieter</span>
                  <span className="truncate text-sm text-gray-500 flex gap-1 items-center">
                    <span data-testid="chat-status-indicator">{isConnected ? 'online' : 'offline'}</span>
                    <span className={cx('h-2 w-2 rounded-full', isConnected ? 'bg-green-500' : 'bg-red-500')}></span>
                  </span>
                </div>
                <IconButton onClick={handleClick}>
                  <Tooltip title="Chat zurücksetzen" arrow>
                    <RestartAltIcon />
                  </Tooltip>
                </IconButton>
                <LangSelect languages={['de', 'en']} inHeader={false} />
              </header>
              <main ref={ref} className="relative flex flex-col flex-grow overflow-y-scroll mb-2 space-y-4 px-10 py-5">
                {chat.messages.length ? (
                  <>
                    {chat.messages.map((message, idx) => (
                      <div
                        key={message.id}
                        data-testid={`chat-message-${idx}`}
                        className={`p-3 rounded-lg max-w-lg ${
                          message.role === 'assistant' ? 'bg-primary-100' : 'bg-secondary-100 ml-auto'
                        }`}
                      >
                        <Message tokenActions={tokenActions}>{message.content}</Message>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="relative w-full h-full">
                    <div className="inset-0 flex justify-center">
                      <h3 className="text-lg font-bold text-center mb-20">
                        {isConnected && scope ? (
                          welcomeMessage[scope]
                        ) : (
                          <div className="flex gap-2 items-center">
                            <span>{t('components.block.chat.not_available')}</span>
                            <Spinner size="small" />
                          </div>
                        )}
                      </h3>
                    </div>
                    <div
                      className={cx(
                        'inset-0 grid grid-cols-1  gap-2 lg:gap-4 place-content-end',
                        isQuestScope || isOnboardingScope ? 'lg:grid-cols-2' : 'lg:grid-cols-3'
                      )}
                    >
                      {scope &&
                        isConnected &&
                        SUGGESTIONS[scope].map((suggestion, idx) => (
                          <div
                            key={suggestion.question}
                            data-testid={`chat-suggestion-${idx}`}
                            onClick={() => chat.submitMessage(suggestion.question)}
                            className="cursor-pointer rounded-lg border border-gray-300 bg-white space-y-1 lg:space-y-2  px-3 py-2.5 lg:px-6 lg:py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-700"
                          >
                            <h6 className="text-sm font-bold">{suggestion.question}</h6>
                            <p className="text-xs">{suggestion.description}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </main>
            </>
          )}
          <footer className="flex w-full items-center">
            <div
              // ref={formRef}
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   inputRef.current?.attributeStyleMap.set('height', '52px');
              //   return chat.handleSubmit(e);
              // }}
              className="flex w-full items-center px-3 py-3"
            >
              <div className="overflow-hidden flex flex-col w-full flex-grow relative items-center">
                <TextareaAutosize
                  ref={inputRef}
                  onFocus={() => setIsExpanded(true)}
                  data-testid="chat-input"
                  onKeyDown={(e) => {
                    if (!e.shiftKey && e.key === 'Enter') {
                      e.preventDefault();
                      chat.input.trim() && handleSend();
                    }
                  }}
                  placeholder={isQuestScope ? t('components.block.chat.prompt') : t('components.block.chat.prompt2')}
                  className={cx(
                    'h-11 m-0 w-full resize-none border-2  focus:outline-0 focus:ring-0 focus-visible:ring-0 py-[10px] pr-10 md:py-3.5 md:pr-12 max-h-52 placeholder-black/50 pl-3 md:pl-4 bg-white rounded-lg overflow-clip text-base',
                    isQuestScope ? 'border-gray-300' : 'border-primary-root'
                  )}
                  value={chat.input}
                  onChange={chat.handleInputChange}
                  // disabled={!isConnected}
                />
                <button
                  // type="submit"
                  onClick={handleSend}
                  className="absolute bottom-1.5 right-2 rounded-lg border border-primary-root bg-primary-root p-0.5 text-white transition-colors enabled:bg-primary-root disabled:text-gray-400 disabled:opacity-10 md:bottom-3 md:right-3"
                  disabled={shouldDisableInput}
                  data-testid="chat-send-button"
                >
                  <span className="" data-state="closed">
                    <SendIcon className="w-6 h-6 fill-current" />
                  </span>
                </button>
              </div>
            </div>
          </footer>
        </div>
      </animated.div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isExpanded}
        onClick={() => setIsExpanded(false)}
      />
    </>
  );
}
