import { ApolloClient, defaultDataIdFromObject, InMemoryCache } from '@apollo/client';
import { link } from '@app/api/apiLink.ts';
import { typePolicies } from '@app/api/cachePolicies.ts';

const isDev = import.meta.env.MODE === 'development';

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: true,
    typePolicies,
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'Tag':
          return `Tag:${responseObject.tag}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  }),
  connectToDevTools: isDev,
});

export default client;
