import { useQueryParams } from '@app/hooks/useQuestionaireRoute';
import OutputView, { NodeReplace } from '@components/block/OutputView/OutputView';
import { Stack, Switch, Typography } from '@mui/material';

import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { findParentNodes, getNodeText } from '@app/utils/getNodeText';
import { DocumentShareToken, parseJwt } from '@app/utils/parseJwt';

import LangSelect from '@components/block/Header/LangSelect';
import { AlertDialog, Button, Field, Input } from '@components/ui';
import { StakeholderType, useAddStakeholderMutation } from '@dieterApi/employee/useStakeholderAddMutation';
import { useAddProcessingConsentMutation } from '@dieterApi/questionnaire/useAddProcessingConsentMutation';
import { useQuestionnaireDocumentConfirmMutation } from '@dieterApi/questionnaire/useQuestionnaireDocumentConfirmMutation';
import { ConsentFrom } from '@dieterApi/questionnaire/useQuestionnaireDocumentShareQuery';
import { useGetShareTokenJWTQuery } from '@dieterApi/sharetoken/useGetShareTokenJWTQuery';
import { Checkbox, Modal, Paper } from '@mui/material';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDashboard } from '../Dashboard/useDashboard';
import './documentshare.sass';

export interface RouteParams {
  localQuestionnaireId?: string;
}

export interface RouteParamsWithQuery extends RouteParams {
  documentNumber?: number;
}

interface ConsentDetails {
  purpose: string;
  data: string[];
  consent: boolean;
}

const ConsenFromMapping: Record<ConsentFrom, StakeholderType> = {
  Bewerber: StakeholderType.Applicant,
  Mitarbeiter: StakeholderType.Employee,
  Vertragspartner: StakeholderType.ContractPartner,
};

export default function DocumentShare() {
  const { t } = useTranslation();
  const query = useQueryParams();
  const { create: createApplication } = useDashboard();
  const { navigation, setNavigation } = useNavigation();

  const token = query.get('token') || '';
  const { data: shareTokenJWT } = useGetShareTokenJWTQuery({
    variables: { id: token },
  });

  const jwt = shareTokenJWT?.getShareTokenJWT?.jwt;
  const tokenInfo = jwt ? parseJwt<DocumentShareToken>(jwt)! : undefined;

  const isQuestionnaireType = tokenInfo?.type === 'Questionnaire';
  const isConsentType = tokenInfo?.type === 'ConsentForm';

  const [value, setValue] = useState('');
  const [hasRead, setHasRead] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [consentAlertOpen, setConsentAlertOpen] = useState(false);
  const [atLeastOneConsent, setAtLeastOneConsent] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [consent, setConsent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [confirmDocument] = useQuestionnaireDocumentConfirmMutation();
  const [addStakeholder] = useAddStakeholderMutation();
  const [addProcessingConsent] = useAddProcessingConsentMutation();
  // There is a timing problem in setting this to true
  // apparently the navigation context is not yet set
  useEffect(() => {
    if (navigation.isPrintView) return;
    setNavigation((nav) => void (nav.isPrintView = true));
  }, [navigation]);

  useEffect(() => {
    return () => {
      setNavigation((nav) => void (nav.isPrintView = false));
    };
  }, []);
  // const thisDoc = user?.documents.find((doc) => doc.id === currentParams.documentId);

  const handleClick = () => {
    if (tokenInfo?.withConfirmation) {
      if (!value) {
        enqueueSnackbar(
          t('route.document_share.route.document_share.snackbar.missing_name', 'Bitte geben Sie einen Namen ein.'),
          { variant: 'error' }
        );
        return;
      }
      if (!consent) {
        enqueueSnackbar(
          t(
            'route.document_share.route.document_share.snackbar.missing_consent',
            'Bitte willigen Sie in die Verarbeitung Ihrer Daten ein.'
          ),
          { variant: 'error' }
        );
        return;
      }
      if (!token)
        enqueueSnackbar(t('common.error.invalid_auth_token', 'Ungültiger Zugriffstoken.'), { variant: 'error' });

      if (['Document', 'ConsentForm'].includes(tokenInfo.type) && jwt) {
        jwt && setAccepted(true);
        isConsentType &&
          tokenInfo.consentFrom &&
          addStakeholder({
            variables: {
              token: jwt,
              name: value,
              type: ConsenFromMapping[tokenInfo.consentFrom],
            },
          });
      } else {
        // type === 'Questionnaire'
        tokenInfo.applicationId &&
          createApplication(tokenInfo.applicationId, tokenInfo.documentNumber, true, undefined, jwt, token, value);
      }
    } else {
      setAccepted(true);
    }
  };

  const handleConsentCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsent(e.target.checked);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    // determine if user has scrolled to bottom
    const element = e.target as HTMLDivElement;
    if (element.scrollHeight - element.scrollTop - element.clientHeight < 10) {
      setHasRead(true);
    }
  };

  const handleHasReadClick = () => {
    if (isConsentType) {
      if (!atLeastOneConsent) {
        setConsentAlertOpen(true);
      } else {
        setHasConfirmed(true);
      }
    } else {
      jwt &&
        confirmDocument({ variables: { token: jwt, name: value } })
          .then(() => {
            enqueueSnackbar(t('route.document_share.snackbar.has_read.success'), {
              variant: 'success',
            });
            setHasConfirmed(true);
          })
          .catch((e) => enqueueSnackbar(e.message, { variant: 'error', stack: e.stack }));
    }
  };

  const handleScrollDebounced = debounce(handleScroll, 100, { leading: true, trailing: true });

  const document_with_confirmation = tokenInfo?.withConfirmation
    ? t('route.document_share.conditionals.document_with_confirmation', 'zur Kenntnisnahme ')
    : '';

  const handleTableCheckbox = (consent: ConsentDetails) => {
    if (!tokenInfo?.consentFrom) return;
    if (consent.consent) setAtLeastOneConsent(true);
    if (jwt) {
      addProcessingConsent({
        variables: {
          name: value,
          consentFrom: tokenInfo?.consentFrom,
          token: jwt,
          purpose: consent.purpose,
          dataCategories: consent.data,
          consent: consent.consent,
        },
      }).catch((e) => enqueueSnackbar(e.message, { variant: 'error', stack: e.stack }));
    }
  };

  const useRenderDieterTableCheckboxNode: NodeReplace['renderNode'] = (node, root) => {
    const [status, setStatus] = useState<ConsentDetails>({ consent: false, purpose: '', data: [] });

    useEffect(() => {
      if (!status.purpose) return;
      handleTableCheckbox(status);
    }, [status]);

    useEffect(() => {
      const nodes = findParentNodes(node.key, root);
      if (!nodes) return;
      const trow = nodes.reverse().find((n) => n.type === 'tr');
      if (trow?.type === 'text' || !trow?.children) return;
      const purpose = getNodeText(trow.children[0]) || '';
      // @ts-expect-error
      const data = trow?.children?.[1].children.map((n) => getNodeText(n)).filter(Boolean);
      setStatus({ ...status, purpose, data });
    }, []);

    return (
      <div className="w-full h-full grid place-items-center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{t('common.no', 'no')}</Typography>
          <Switch
            checked={status.consent}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              // TODO: here's an example of extracting the data related to the switch
              setStatus({ ...status, consent: e.target.checked });
            }}
          />
          <Typography>{t('common.yes', 'yes')}</Typography>
        </Stack>
      </div>
    );
  };

  return !accepted ? (
    <div className="dtDocumentShare flex flex-col gap-10">
      <div className="fixed top-6 right-6">
        <LangSelect languages={['de', 'en']} inHeader={false} />
      </div>
      <div className="header flex gap-10">
        <h1 className="text-3xl font-medium">
          {isQuestionnaireType
            ? t('route.document_share.accept_form.title.questionnaire', 'Schulungsanforderung')
            : isConsentType
            ? t('route.document_share.accept_form.title.consent_form')
            : t('route.document_share.accept_form.title.document', 'Dokumentenfreigabe')}
        </h1>
        <img src="/assets/images/dieter_logo.svg" alt="Dieter" />
      </div>
      {tokenInfo ? (
        <>
          {isQuestionnaireType ? (
            <Trans
              t={t}
              i18nKey="route.document_share.accept_form.description.questionnaire"
              shouldUnescape
              values={tokenInfo}
            >
              <p>
                {/* @ts-expect-error */}
                {{ companyName: tokenInfo.companyName }} lädt dich ein die {/* @ts-expect-error */}
                <strong>{{ documentTitle: tokenInfo.documentTitle }}</strong> durchzuarbeiten.
              </p>
            </Trans>
          ) : (
            <Trans
              t={t}
              i18nKey={
                isConsentType
                  ? 'route.document_share.accept_form.description.consent_form'
                  : 'route.document_share.accept_form.description.document'
              }
              shouldUnescape
              values={{
                ...tokenInfo,
                withConfirmation: document_with_confirmation,
              }}
            />
          )}
          {tokenInfo.withConfirmation && (
            <>
              <Trans
                t={t}
                i18nKey="route.document_share.accept_form.description.confirmation_request"
                shouldUnescape
                values={{
                  action: t(
                    `route.document_share.conditionals.confirmation_request_action.${tokenInfo?.type || 'Document'}`
                  ),
                }}
              />
              <Field className="mb-20" htmlFor="fullname">
                <Input
                  // disabled={!value}
                  required
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  onKeyPress={(e) => (e.key === 'Enter' ? handleClick() : null)}
                  type="text"
                  placeholder={t('route.document_share.accept_form.fields.fullname.placeholder')}
                  value={value}
                  id="fullname"
                  data-testid="fullname-field"
                />
              </Field>
              <div className="checkbox flex gap-10">
                <Checkbox
                  disableRipple={false}
                  onChange={handleConsentCheckbox}
                  value={consent}
                  required={true}
                  checked={consent}
                  data-testid="consent-checkbox"
                />
                <Trans
                  t={t}
                  i18nKey="route.document_share.accept_form.fields.consent_checkbox.label"
                  components={{
                    root: <div className="checkbox-label" />,
                    paragraph: <span onClick={() => setConsent(!consent)} />,
                    span: <span />,
                    'router-link': <Link to="/privacy" target="_blank" />,
                  }}
                />
              </div>
            </>
          )}
          <Button
            onClick={handleClick}
            disabled={tokenInfo.withConfirmation && (!value || !consent)}
            data-testid={'button-submit'}
          >
            {t(`route.document_share.accept_form.submit.${tokenInfo?.type || 'Document'}`)}
          </Button>
        </>
      ) : (
        <p>{t('route.document_share.invalid_url')}</p>
      )}
    </div>
  ) : (
    <>
      <Modal
        open={true}
        hideBackdrop={true}
        disableEnforceFocus={true}
        data-testid="doc-modal"
        disablePortal
        sx={{ zIndex: '40' }}
      >
        <div className="">
          {tokenInfo?.withConfirmation && (
            <div
              className={cx(
                'top-3 w-[95vw] md:w-[80vw] max-w-[800px] mx-auto self-center z-50'
                // hasRead && !hasConfirmed && 'animate-bounce'
              )}
            >
              <div>
                <Button onClick={handleHasReadClick} disabled={hasConfirmed} data-testid={'button-hasread'}>
                  {hasConfirmed
                    ? t('route.document_share.tooltip.button_label_confirmed')
                    : isConsentType
                    ? t('route.document_share.tooltip.button_label_consent')
                    : t('route.document_share.tooltip.button_label')}
                </Button>
              </div>
            </div>
          )}
          <Paper
            className="absolute top-1/2 left-1/2 overflow-y-auto overflow-x-hidden h-[90vh] w-[95vw] md:w-[80vw] max-w-[800px] mt-[80px] md:mt-10 lg:mt-4 -translate-y-1/2 -translate-x-1/2 z-30 pointer-events-auto "
            elevation={10}
            onScroll={handleScrollDebounced}
          >
            {tokenInfo?.localQuestionnaireId && (
              <OutputView
                localQuestionnaireId={tokenInfo.localQuestionnaireId}
                documentNumber={tokenInfo?.documentNumber}
                token={jwt}
                consumption={false}
                replace={[{ classNameMatch: 'DieterTableCheckbox', renderNode: useRenderDieterTableCheckboxNode }]}
              />
            )}
          </Paper>
        </div>
      </Modal>
      <AlertDialog
        acceptText={t('route.document_share.consent_alert_button')}
        open={consentAlertOpen}
        setOpen={setConsentAlertOpen}
        message={t('route.document_share.consent_alert_message')}
        onCancel={() => setConsentAlertOpen(false)}
        onAccept={() => {
          setConsentAlertOpen(false);
          setHasConfirmed(true);
        }}
      />
    </>
  );
}
