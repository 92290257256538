import { useUser } from '@app/context/UserContext';
import { useDashboard } from '@app/routes/Dashboard/useDashboard';
import { AlertDialog, Button, Loading } from '@components/ui';
import {
  User,
  UserQuestionnaireApplicationTopic,
  UserTopicStatusApplicationsQuestionnaires,
} from '@dieterApi/user/useUserQuery';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Menu, MenuItem, TableCell, TableRow, Tooltip } from '@mui/material';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { gql } from '@apollo/client';
import { Topics } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { getTopicStates } from '@app/utils/getTopicStates';
import { ProcessingConsent } from '@dieterApi/questionnaire/useAddProcessingConsentMutation';
import { ConsentFrom } from '@dieterApi/questionnaire/useQuestionnaireDocumentShareQuery';
import { useWithdrawProcessingConsentMutation } from '@dieterApi/questionnaire/useWithdrawProcessingConsentMutation';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { useUserValueDeleteMutation } from '@dieterApi/user/useUserValueDeleteMutation';
import { useTranslation } from 'react-i18next';

export interface ConsentFormTableMeta extends MUIDataTableMeta {
  rowData: [
    string, // index
    string, // description
    string, // consentFrom
    string | null, // createdAt
    UserQuestionnaireApplicationTopic | null,
    string,
    string,
    ProcessingConsent[],
  ];
}

interface Props {
  topic: UserTopicStatusApplicationsQuestionnaires;
  topicStates: ReturnType<typeof getTopicStates>;
}

export function ConsentForms({ topic, topicStates }: Props): JSX.Element {
  const { t } = useTranslation();
  const { user } = useUser();
  const dashboard = useDashboard();
  const {
    navigation: { roadBlock },
    setNavigation,
  } = useNavigation();
  const blankEntry = { index: '', description: '', consents: [] };
  const [newEntry, setNewEntry] = useImmer<ConsentFormData>(blankEntry);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<ConsentFormTableMeta | null>(null);
  const [withdrawConsent] = useWithdrawProcessingConsentMutation();
  // const [activeQuestionnaire, setActiveQuestionnaire] = useState<UserQuestionnaireApplicationTopic | null>(null);
  const consentFormData = useMemo(() => {
    const parsedData = extractConsentFormData(user);
    return newEntry.index ? [...parsedData, newEntry] : parsedData;
  }, [user, newEntry]);

  const [rowsExpanded, setRowsExpanded] = useState<number[]>([]);

  const [addUserValue] = useUserValueAddMutation();
  const [deleteUserValue] = useUserValueDeleteMutation();

  // when new input field on manual entry row is added, focus it
  const handleRef = useCallback<(node: HTMLInputElement) => void>((node) => {
    if (node !== null) {
      node.focus();
    } else {
      // clear focus
      window.focus();
      // document.activeElement?.blur();
    }
  }, []);

  const handleDelete = (tableMeta: ConsentFormTableMeta) => {
    const index = tableMeta.rowData?.[0] as string;
    if (index === 'new') return;
    const keys = ['CONSENTFORM_DESCRIPTION'];
    keys.forEach((key) => deleteUserValue({ variables: { key, index }, context: { userId: user?.id || '' } }));
  };

  const handleAdd = () => {
    addUserValue({
      variables: { key: 'CONSENTFORM_DESCRIPTION', value: '', createIndex: true },
      context: { userId: user?.id || '' },
    }).then((res) => {
      if (res.data?.addUserValue) {
        const app = user?.topics
          .find((t) => t.id === Topics.INFO)
          ?.applications.find((a) => a.id === 'app-info-consent-create');
        app && dashboard.create(app.id, undefined, true, res.data.addUserValue.index);
      }
    });
  };

  const handleUpdate = (consentForm: ConsentFormData, description: string) => {
    addUserValue({
      variables: { key: 'CONSENTFORM_DESCRIPTION', value: description, index: consentForm.index },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: cache.identify({ __typename: 'UserValue', id: data?.addUserValue.id }),
          data: data?.addUserValue,
          fragment: gql`
            fragment NewUserValue on UserValue {
              id
              key
              value
              index
              createdAt
            }
          `,
        });
      },
    });
  };

  const handleOpenQuestionnaire = (meta: ConsentFormTableMeta) => {
    dashboard.open(meta.rowData[4]!.id);
  };

  const handleShowContract = (
    meta: ConsentFormTableMeta,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: 'show' | 'download'
  ) => {
    if (topicStates.isLocked) {
      roadBlock.open(topic);
      return;
    }
    const questionnaire = meta.rowData[4]!;

    setNavigation((nav) => {
      nav.documentPaper = {
        modalOpen: true,
        questionnaire,
        index: 0,
      };
    });
    // setActiveQuestionnaire(meta.rowData[4]!);
    // setAnchorEl(e.currentTarget);
  };

  const handleWithdrawal = (consentgroup: ProcessingConsent[]) => {
    const withdrawn = consentgroup.some((c) => c.withdrawn);
    consentgroup.forEach((c) => {
      withdrawConsent({
        variables: {
          id: c.id,
          withdrawal: !withdrawn,
        },
      });
    });
  };

  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'index',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'description',
      label: 'Beschreibung',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowData?.[0];
          const isNew = tableMeta.rowData?.[0] === 'new';
          const consentForm = consentFormData.find((i) => i.index === index);
          const originalValue = consentForm?.description || '';
          return (
            <div>
              <input
                ref={isNew ? handleRef : null}
                className="w-full bg-transparent text-sm"
                placeholder="Kurzbeschreibung"
                type="text"
                value={value}
                onChange={(e) => {
                  updateValue(e.target.value);
                }}
                onBlur={(e) => {
                  updateValue(e.target.value);
                  handleUpdate(consentForm!, e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                    // handleUpdate(consentForm!, value);
                  }
                  // blur on escape
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                  }
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: 'consentFrom',
      label: 'Einwilligung von',
    },
    {
      name: 'createdAt',
      label: 'Erfasst am',
      options: {
        customBodyRender: (value) => {
          return value ? new Date(value).toLocaleDateString() : '';
        },
      },
    },
    {
      name: 'questionnaire',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'actions',
      label: 'Aktionen',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const meta = tableMeta as ConsentFormTableMeta;
          const index = meta.rowData[0];
          const questionnaire = meta.rowData[4];
          const isNotFinished = (questionnaire?.progress || 0) < 1;
          if (isNotFinished) {
            return (
              <Link to="#" onClick={() => handleOpenQuestionnaire(meta)}>
                Fortsetzen
              </Link>
            );
          } else {
            return (
              <>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Anzeigen">
                  <IconButton size="small" onClick={(e) => handleShowContract(meta, e, 'show')}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Bearbeiten">
                  <IconButton size="small" onClick={(e) => handleOpenQuestionnaire(meta)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            );
          }
        },
        customHeadLabelRender: () => '',
      },
    },
    {
      name: 'delete',
      label: 'Löschen',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const meta = tableMeta as ConsentFormTableMeta;
          const index = meta.rowData[0];
          const isNew = index === 'new';

          return (
            <div className="z-30">
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={isNew ? 'Speichern' : 'Einwilligungserklärung löschen'}
              >
                <IconButton
                  className="text-gray-500 hover:text-danger"
                  size="small"
                  onClick={() => {
                    if (isNew) {
                      // handleSave(description);
                    } else {
                      setDeleteRow(meta);
                      setDeleteAlertOpen(true);
                    }
                  }}
                  data-testid={`buton-action-consentForms-${meta.rowIndex}`}
                >
                  {isNew ? <SaveIcon fontSize="small" /> : <DeleteIcon fontSize="small" />}
                </IconButton>
              </Tooltip>
            </div>
          );
        },
        customHeadLabelRender: () => '',
      },
    },
    {
      name: 'consents',
      options: {
        display: 'excluded',
      },
    },
  ];

  const options: MUIDataTableOptions = {
    sortOrder: {
      name: 'name',
      direction: 'asc',
    },
    download: false,
    print: false,
    elevation: 0,
    // onRowClick: handleClick,
    selectableRows: 'none',
    selectableRowsHeader: false,
    rowsExpanded,
    // selectableRowsOnClick: true,
    isRowExpandable(dataIndex, expandedRows) {
      return true; //consentFormData[dataIndex].consents.length > 0;
    },
    onRowExpansionChange(currentRowsExpanded, allRowsExpanded, rowsExpanded) {
      console.log('current', currentRowsExpanded);
      console.log('all', allRowsExpanded);
      setRowsExpanded(allRowsExpanded.map((i) => i.index));
    },
    expandableRowsOnClick: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const row = consentFormData[rowMeta.dataIndex];
      // group consents in new object by name
      const groupedConsents: { [key: string]: ProcessingConsent[] } = {};
      row.consents.forEach((c) => {
        if (!groupedConsents[c.name]) {
          groupedConsents[c.name] = [];
        }
        groupedConsents[c.name].push(c);
      });
      const noConsents = row.consents.length === 0;
      return (
        <TableRow>
          <TableCell colSpan={rowData.length}>
            <div className="flex flex-col bg-gray-100 px-2 py-4 gap-2">
              <span className="font-medium mb-2">Dokumentierte Einwilligungen:</span>
              {noConsents && 'Keine'}
              {Object.keys(groupedConsents).map((name) => {
                const consentGroup = groupedConsents[name];
                const total = consentGroup.length;
                const accepted = consentGroup.filter((c) => c.consent).length;
                const date = new Date(consentGroup[0].createdAt);
                const allAccepted = accepted === total;

                const missingConsentPurpose = consentGroup.filter((c) => !c.consent).map((c) => c.purpose);
                const tooltip = (
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-base">Fehlende Einwilligungen:</div>
                    {missingConsentPurpose.map((p, i) => (
                      <div key={i}>{p}</div>
                    ))}
                  </div>
                );
                const withdrawn = consentGroup.some((c) => c.withdrawn);

                return (
                  <div className="flex gap-6 justify-between items-center">
                    <span className="grow">{name}</span>
                    <button
                      className="underline text-primary-root hover:text-primary-700"
                      onClick={() => handleWithdrawal(consentGroup)}
                    >
                      {withdrawn ? 'Widerruf Rückgängig' : 'Widerruf vermerken'}
                    </button>
                    <span>{date.toLocaleDateString()}</span>
                    <span>
                      {accepted} / {total}
                    </span>
                    <Tooltip
                      title={
                        withdrawn ? 'Einwilligung widerrufen' : allAccepted ? 'Alle Einwilligungen erteilt' : tooltip
                      }
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                    >
                      <span className="hover:text-gray-500">
                        {allAccepted && !withdrawn ? (
                          <CheckCircleOutlineIcon color="success" />
                        ) : accepted === 0 || withdrawn ? (
                          <ErrorOutlineIcon color="error" />
                        ) : (
                          <ErrorOutlineIcon color="warning" />
                        )}
                      </span>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </TableCell>
        </TableRow>
      );
    },

    customToolbar: () => (
      <Button className="text-base mb-3" onClick={handleAdd} icon={<AddIcon />} disabled={user?.isReadOnly}>
        Neues Formular erstellen
      </Button>
    ),
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    textLabels: {
      selectedRows: {
        text: 'Zeilen ausgewählt',
        delete: 'Löschen',
        deleteAria: 'Ausgewählte Zeilen löschen',
      },
      body: {
        noMatch: 'Keine Einträge vorhanden',
      },
    },
  };

  return (
    <div className="mb-5">
      {consentFormData ? (
        <MUIDataTable
          columns={columns}
          data={consentFormData}
          title="Deine Einwilligungserklärungen"
          options={options}
        />
      ) : (
        <Loading />
      )}
      <AlertDialog
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        message={
          <>
            Möchtest du die Einwilligungserklärung <b>{deleteRow?.rowData[1]}</b> wirklich löschen? Damit werden auch
            sämtliche bisher dokumentierten Einwilligungen entfernt.
          </>
        }
        onAccept={() => deleteRow && handleDelete(deleteRow)}
      />
    </div>
  );
}

interface DocumentMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  questionnaire: UserQuestionnaireApplicationTopic | null;
}

export const DocumentMenu = ({ open, anchorEl, handleClose, questionnaire }: DocumentMenuProps): JSX.Element => {
  const documentLabels = questionnaire?.application.documentLabels;
  const { setNavigation } = useNavigation();

  const handleClick = (documentLabel: string) => {
    const index = documentLabels ? documentLabels.findIndex((label) => label === documentLabel) : -1;

    if (index < 0) return;
    questionnaire &&
      setNavigation((nav) => {
        nav.documentPaper = {
          modalOpen: true,
          questionnaire,
          index,
        };
      });
  };

  return (
    <>
      {' '}
      <Menu
        className="dtAccountMenu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {documentLabels?.map((dl) => (
          <MenuItem key={dl} onClick={() => handleClick(dl)}>
            {dl}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface ConsentFormData {
  index: string;
  description: string;
  consentFrom?: ConsentFrom;
  questionnaire?: UserQuestionnaireApplicationTopic;
  consents: ProcessingConsent[];
  createdAt?: string;
}

const extractConsentFormData = (user: User | undefined): ConsentFormData[] => {
  if (!user) return [];
  const consentFormData: ConsentFormData[] = [];
  user.userValues.forEach((userValue) => {
    if (userValue.key === 'CONSENTFORM_DESCRIPTION') {
      const description = userValue.value;
      const questionnaire = user.questionnaires?.find((q) => q.userValueIndex === userValue.index);
      const consentFrom = (user.userValues.find((uv) => uv.key === 'CONSENT_FROM' && uv.index === userValue.index)
        ?.value || '') as ConsentFrom;

      const consents =
        user.company?.processingConsents.filter((c) => c.localQuestionnaireId === questionnaire?.id) || [];
      consentFormData.push({
        description,
        index: userValue.index,
        consents,
        consentFrom,
        questionnaire,
        createdAt: userValue.createdAt,
      });
    }
  });
  return consentFormData;
};
