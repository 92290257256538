import { useNavigation } from '@app/hooks/useNavigation';
import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { useUserValues } from '@app/hooks/useUserValues';
import { Locales } from '@components/block/Header/LangSelect';
import { Loading } from '@components/ui';

import AllowedValueTypes from '@legalosApi/types/AllowedValueTypes';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageStep as Step } from '../../utils';
import { Props as BaseProps } from '../Step';

import QuestStepChoice from '../components/quest-step-choice';

export type Props = BaseProps<Step>;

export function ChoiceStep(props: Props) {
  const { i18n } = useTranslation();
  const { setNavigation } = useNavigation();
  const { question } = props.step;
  const { jwt } = useQuestionaireRoute();
  const tooltip = question.tags.process.some((tag) => tag === 'TOOLTIP');

  async function onSelect(value: string) {
    await props
      .onUpdateQuestion(question._id, value, true, jwt, jwt ? (i18n.language.slice(0, 2) as Locales) : undefined)
      .then(() => {
        // show "saved" animation
        setNavigation((nav) => void (nav.showSavedAnimation = true));
      });
    props.onAdvance(props.step.id);
  }

  const [value, setValue] = useState<AllowedValueTypes>(question.value ? question.value : '');

  // this handles the automatic answering of AUTO questions while displaying the Loading component
  const [blocked, setBlocked] = useState(true);
  const { updateFromUserValue } = useUserValues();
  useEffect(() => {
    // we disable automatic update from uservalues if questionnaire is edited with a token
    // because the editing user is not the owner of the questionnaire, hence his userValues would
    // be invalid
    if (jwt) {
      setBlocked(false);
    } else {
      setBlocked(
        updateFromUserValue(props.step, props.questionnaire, props.onUpdateQuestion, props.onAdvance, setValue)
      );
    }
  }, []);
  if (blocked) return <Loading />;

  return (
    <QuestStepChoice
      tooltip={tooltip}
      options={question.options}
      questionText={question.questionText}
      description={question.description}
      value={question.value || value}
      onSelect={onSelect}
    />
  );
}
