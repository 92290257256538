import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { getNodeText } from '@app/utils/getNodeText';
import { useOpenQuestionnaireMutation } from '@dieterApi/questionnaire/useOpenQuestionnaireMutation';
import { UserQuestionnaireApplication } from '@dieterApi/user/useUserQuery';
import { useLegalOSLazyQuery } from '@legalosApi/graphql/queries';
import useLegalOSRoutePath from '@legalosApi/hooks/useLegalOSRoutePath';
import { IQuestion } from '@legalosApi/types/IQuestion';
import { DocumentNode, DocumentTagNode, DocumentTextNode } from '@legalosApi/types/IQuestionnaire';
import { ISection } from '@legalosApi/types/ISection';
import cx from 'classnames';
import $ from 'jquery';
import find from 'lodash/find';
import * as React from 'react';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Locales } from '../Header/LangSelect';
import { ConsumerPanel } from './ConsumerPanel';
import { DocumentCallToAction } from './DocumentCallToAction';
import { DocumentSkeleton } from './DocumentSkeleton';
import { TrialPanel } from './TrialPanel';
import './outputview.sass';

const questionListIdIndexSeparator = '_';
interface IOutputView {
  /**
   * The ID of the questionnaire to display.
   */
  localQuestionnaireId: string;

  /**
   * Whether to trigger a print window in the browser
   */
  triggerPrint?: boolean;

  /**
   * The number of the document to display.
   */
  documentNumber?: number;

  /**
   * The token to use for authentication.
   */
  token?: string;

  /**
   * Whether to display only the content without the header
   */
  onlyContent?: boolean;

  /**
   * Callback function to be called when the component is closed.
   */
  onClose?: () => void;

  /**
   * Whether the component is locked and cannot be interacted with.
   */
  locked?: boolean;

  /**
   * Whether the document can be consumed
   */
  consumption?: boolean;

  /**
   * Whether the user is in a trial phase.
   */
  trialing?: boolean;
  /**
   *  This needs to be called, when the questionnaire was opened and possibly recreated at LegalOs
   *  due to their 2 weeks persistencs timeout to let parent components know about the then changed questionnaireId.
   */
  onOpenQuestionnaire?: (questionnaire: UserQuestionnaireApplication) => void;

  /*
   * the percentage of blurred text of the document content (value must be between "0" and "1")
   */
  lockedBlurRatio?: number;

  /**
   *  This allows to replace nodes in the document with custom components
   */
  replace?: NodeReplace[];
}

export interface NodeReplace {
  classNameMatch: string;
  renderNode: (node: DocumentNode, root: DocumentNode[]) => React.ReactElement;
}

const OutputView = React.memo(
  function X({
    localQuestionnaireId,
    triggerPrint = false,
    documentNumber,
    token,
    onClose,
    onlyContent = false,
    locked = false,
    consumption = true,
    trialing = false,
    onOpenQuestionnaire,
    lockedBlurRatio = 1,
    replace,
  }: IOutputView) {
    const { t, i18n } = useTranslation();
    const { user } = useUser();
    const [fetchDocument, { loading: loading2, data }] = useLegalOSLazyQuery('questionnaireDoc', {
      fetchPolicy: 'network-only',
    });
    const [openQuestionnaire, { loading: loading1, data: localQuestionnaireData }] = useOpenQuestionnaireMutation();
    const {
      navigation: { newDocuments, dashboardLocked },
      setNavigation,
    } = useNavigation();
    const activeScrollTarget = undefined;
    const questionnaire = data?.questionnaire;
    const localQuestionnaire = localQuestionnaireData?.openQuestionnaire;
    const { enqueueSnackbar } = useSnackbar();

    // get current year
    const currentYear = new Date().getFullYear();

    // We call the onOpenQuestionnaire callback when the questionnaire was opened and possibly recreated to let
    // parent components know about the then changed questionnaireId.
    React.useEffect(() => {
      if (localQuestionnaire && onOpenQuestionnaire) {
        onOpenQuestionnaire(localQuestionnaire);
      }
    }, [localQuestionnaire]);

    // 1. for the print view we disable the consent button
    React.useEffect(() => {
      triggerPrint && $('#usercentrics-root').hide();
    }, []);

    // 2. we mark new documents as "not new" in the navigation state once they are opened
    React.useEffect(() => {
      newDocuments[localQuestionnaireId] &&
        setNavigation((nav) => void (nav.newDocuments[localQuestionnaireId][(documentNumber || 1) - 1] = false));
    }, [newDocuments]);

    React.useEffect(() => {
      openQuestionnaire({
        variables: {
          localQuestionnaireId,
          token,
        },
      })
        .then(({ data }) => {
          data &&
            fetchDocument({
              variables: {
                input: {
                  _id: data?.openQuestionnaire.questionnaireId,
                  token: data?.openQuestionnaire?.accessToken,
                  // in the case of access by token we also pass the selected locale since that cannot
                  // be determined by the user's locale
                  locale: data?.openQuestionnaire?.accessToken ? (i18n.language.slice(0, 2) as Locales) : undefined,
                },
              },
            });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
        });
    }, [localQuestionnaireId]);

    React.useEffect(() => {
      // if (window.top && data1) {
      //   window.top.document.title = data1?.openDocument.questionnaire.
      // }
      data && triggerPrint && setTimeout(window.print, 1000);
    }, [data]);
    // if (!questionnaire) return <></>;
    // React.useEffect(() => {
    //   if (activeScrollTarget?.documentScrollTargetId) {
    //     document
    //       .getElementById(activeScrollTarget.documentScrollTargetId)
    //       ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //   }
    // }, [activeScrollTarget?.documentScrollTargetId]);

    const nodeKeyToConsumedQuestionIds = React.useMemo(
      () => questionnaire && getNodeKeyToAllConsumedQuestionsOfAncestors(questionnaire.document),

      [JSON.stringify(questionnaire?.document)]
    );
    const docDate = localQuestionnaire?.updatedAt ? new Date(localQuestionnaire?.updatedAt) : new Date();
    const loading = loading1 || loading2;

    let thisDoc: DocumentNode[];
    if (documentNumber && questionnaire?.document[0]) {
      thisDoc = splitDocument(questionnaire.document, documentNumber);
    } else {
      thisDoc = questionnaire?.document || [];
    }

    const blurredNodeKeys = useMemo(() => {
      if (!locked) return [];
      const keys = getTextNodeKey(thisDoc);
      keys.splice(0, Math.floor(keys.length * lockedBlurRatio));
      return keys;
    }, [thisDoc, lockedBlurRatio]);

    const documentContent =
      questionnaire && nodeKeyToConsumedQuestionIds ? (
        <div id="documentContent" data-testid="document-content">
          <style>{questionnaire.styles}</style>
          {thisDoc.map((node) =>
            renderDocumentNode({
              node,
              activeScrollTarget,
              nodeKeyToConsumedQuestionIds,
              blurredNodeKeys,
              root: thisDoc,
              replace,
            })
          )}
        </div>
      ) : null;

    const documentContainer = (
      <div
        className={cx(
          'dtOutputView__document md:px-5 relative break-words mt-3',
          {
            'select-none': locked,
          },
          locked && `preview-background-${user?.locale}`
        )}
      >
        {loading ? (
          <DocumentSkeleton />
        ) : (
          questionnaire &&
          nodeKeyToConsumedQuestionIds && (
            <>
              {dashboardLocked && <DocumentCallToAction onClose={onClose} minimal={true} />}
              {trialing && <TrialPanel />}
              {documentContent}
              {/* {dashboardLocked && <DocumentCallToAction onClose={onClose} />} */}
              <div className="dtOutputView__document__footer" data-testid="document-footer">
                {t('components.output-view.footer')} {currentYear}.
              </div>
            </>
          )
        )}
      </div>
    );

    return !onlyContent ? (
      <>
        <div className="dtOutputView__documentHeader flex gap-20 items-center">
          <div className="flex-1" />
          <div className="flex-3 dtOutputView__documentHeader__logo">
            <img src="/assets/images/dieter_logo.svg" alt="Dieter" />
          </div>
          <div className="flex-6 dtOutputView__documentHeader__signature">
            {docDate && localQuestionnaire && (
              <Trans
                t={t}
                i18nKey="components.output-view.doc-details"
                values={{ date: docDate?.toLocaleDateString() }}
              ></Trans>
            )}
          </div>
          <div className="flex-1 hidden md:block">
            <img src="/assets/images/dieter_kopf.svg" alt="Dieter" />
          </div>
          <div className="flex-1" />
        </div>
        {!triggerPrint &&
          !locked &&
          !dashboardLocked &&
          localQuestionnaire &&
          !loading &&
          documentContent &&
          consumption && <ConsumerPanel questionnaireId={localQuestionnaireId} documentNumber={documentNumber} />}

        <div className="dtOutputView flex gap-20">
          <div className="flex-1" />
          {documentContainer}
          <div className="flex-1" />
        </div>
      </>
    ) : (
      <>{documentContainer}</>
    );
  },
  (prevProps: Readonly<IOutputView>, nextProps: Readonly<IOutputView>) =>
    JSON.stringify(prevProps?.localQuestionnaireId) === JSON.stringify(nextProps?.localQuestionnaireId) &&
    prevProps.onClose === nextProps.onClose
);

function getTextNodeKey(nodes: DocumentNode[]): string[] {
  return nodes.reduce((acc, curr) => {
    if (curr.type === 'text') {
      return [...acc, curr.key];
    } else if (curr.children?.length) {
      return [...acc, ...getTextNodeKey(curr.children)];
    }
    return acc;
  }, [] as string[]);
}

/* istanbul ignore next */
const renderDocumentNode = ({
  node,
  activeScrollTarget,
  nodeKeyToConsumedQuestionIds,
  keyIndex = 0,
  blurredNodeKeys,
  root,
  replace,
}: {
  node: DocumentNode;
  activeScrollTarget: IQuestion | ISection | undefined;
  keyIndex?: number;
  nodeKeyToConsumedQuestionIds: Map<string, Set<string>>;
  blurredNodeKeys: string[];
  root: DocumentNode[];
  replace?: NodeReplace[];
}): React.ReactElement => {
  if (replace?.length && node.type !== 'text') {
    for (const { classNameMatch, renderNode } of replace) {
      if (node.attributes?.className === classNameMatch) {
        return renderNode(node, root);
      }
    }
  }

  // We skip the hr nodes, because they throw an error while rendering with React.createElement
  if (node.type === 'hr' || node.type === 'img') return <></>;
  if (node.type === 'text') {
    // For text nodes, we use the index of this node (passed down from the parent) to allow for better text diffing. The backend tries to give each node a unique name to account for more complex
    // document rendering cases.
    const isBlurred = blurredNodeKeys.includes(node.key);
    return renderTextNode({ text: node.text, key: `text-${keyIndex}` }, isBlurred);
  }

  // For the special class DieterInsertMarker we replace with a part which is defined by the text in that node
  if (node.attributes?.className === 'DieterInsertMarker') {
    node = replaceInsertNode(node);
  }

  return (
    <TypedNode
      activeScrollTarget={activeScrollTarget}
      node={node}
      key={`${node.key}`}
      keyIndex={keyIndex}
      nodeKeyToConsumedQuestionIds={nodeKeyToConsumedQuestionIds}
      blurredNodeKeys={blurredNodeKeys}
      root={root}
      replace={replace}
    />
  );
};

const replaceInsertNode = (node: DocumentTagNode): DocumentTagNode => {
  // This is a first test of the implementation how to replace a node.
  const text = getNodeText(node);
  const newNode: DocumentTagNode = {
    type: 'p',
    key: node.key,
    children: [
      {
        type: 'text',
        key: node.key,
        text: `This node "${text}" has been replaced`,
      },
    ],
  };
  return newNode;
};

/* istanbul ignore next */
const TypedNode = React.memo(
  function X({
    activeScrollTarget,
    node,
    keyIndex,
    nodeKeyToConsumedQuestionIds,
    blurredNodeKeys,
    root,
    replace,
  }: {
    activeScrollTarget: IQuestion | ISection | undefined;
    node: DocumentTagNode;
    keyIndex: number;
    nodeKeyToConsumedQuestionIds: Map<string, Set<string>>;
    blurredNodeKeys: string[];
    root: DocumentNode[];
    replace?: NodeReplace[];
  }) {
    const { generatePath } = useLegalOSRoutePath();
    const pathname = generatePath({ focusedSubSectionId: null });
    const isNodeInfluencedByActiveQuestionAnswer = isInfluencedByActiveQuestionAnswer(activeScrollTarget, node);
    const isNodeActiveQuestionAnswer = isActiveQuestionAnswer(activeScrollTarget, node);
    // Is the document node an answer to a question, but not related to the currently focused one?
    const isNodeIndependentQuestionAnswer =
      // Is this document node linked to a particular question's answer?
      isQuestion(activeScrollTarget) &&
      // Is it even the currently focused question? We want to highlight that.
      !isNodeActiveQuestionAnswer &&
      // Or is the currently focused answer used as an input to a formula that determined the element we're currently rendering?
      !isNodeInfluencedByActiveQuestionAnswer;

    const children = node.children?.map((child, childIndex) =>
      renderDocumentNode({
        node: child,
        activeScrollTarget,
        keyIndex: childIndex,
        nodeKeyToConsumedQuestionIds,
        blurredNodeKeys,
        root,
        replace,
      })
    );

    return React.createElement(
      node.type,
      {
        ...node.attributes,
        ...(isAnchorElementAttributes(node.attributes) && {
          href: node.attributes.href?.startsWith('#')
            ? // It is a link to an element on the same page if it starts with "#"
              pathname + node.attributes.href
            : // otherwise it is a complete url
              node.attributes.href,
        }),
        className: [
          // We assign the class names from the document
          ...(node.attributes?.className?.split(' ') || []),
          // but add some highlighting classes depending on the question status
          ...(isNodeInfluencedByActiveQuestionAnswer ? ['proxyHighlighted'] : []),
          ...(isNodeActiveQuestionAnswer ? ['highlighted'] : []),
          ...(isNodeIndependentQuestionAnswer ? ['not-highlighted'] : []),
        ].join(' '),
        key: `${node.key}-${keyIndex}-element`,
      },
      children
    );
  },
  function arePropsEqual(prevProps, nextProps) {
    // Was any question answer in this node or its descendants highlighted previously? If so, what was the ID of that question?
    const prevActiveNodeInTree =
      isQuestion(prevProps.activeScrollTarget) &&
      !!prevProps.nodeKeyToConsumedQuestionIds.get(prevProps.node.key)?.has(prevProps.activeScrollTarget._id) &&
      prevProps.activeScrollTarget._id;
    // Was any question answer in this node or its descendants be highlighted? If so, what will the ID of that question be?
    const nextActiveNodeInTree =
      isQuestion(nextProps.activeScrollTarget) &&
      !!nextProps.nodeKeyToConsumedQuestionIds.get(nextProps.node.key)?.has(nextProps.activeScrollTarget._id) &&
      nextProps.activeScrollTarget._id;
    // Was any question ID highlighted as consumed by the currently active question in this node or its descendants? If so, what were the IDs of those questions?
    const prevConsumedNodesInTree =
      (isQuestion(prevProps.activeScrollTarget) &&
        prevProps.activeScrollTarget.consumedByQuestionIds
          ?.map(
            (consumedByQuestionId) =>
              (prevProps.nodeKeyToConsumedQuestionIds.get(prevProps.node.key)?.has(consumedByQuestionId) ||
                (!consumedByQuestionId.includes(questionListIdIndexSeparator) &&
                  prevProps.nodeKeyToConsumedQuestionIds.get(prevProps.node.key)?.has('!!!' + consumedByQuestionId))) &&
              consumedByQuestionId
          )
          .filter((questionId) => !!questionId)) ||
      [];

    // Will any question ID be highlighted as consumed by the currently active question in this node or its descendants? If so, what will the IDs of those questions be?
    const nextConsumedNodesInTree =
      (isQuestion(nextProps.activeScrollTarget) &&
        nextProps.activeScrollTarget.consumedByQuestionIds
          ?.map(
            (consumedByQuestionId) =>
              (nextProps.nodeKeyToConsumedQuestionIds.get(nextProps.node.key)?.has(consumedByQuestionId) ||
                (!consumedByQuestionId.includes(questionListIdIndexSeparator) &&
                  nextProps.nodeKeyToConsumedQuestionIds.get(nextProps.node.key)?.has('!!!' + consumedByQuestionId))) &&
              consumedByQuestionId
          )
          .filter((questionId) => !!questionId)) ||
      [];
    return (
      JSON.stringify({
        node: prevProps.node,
        activeNodeInTree: prevActiveNodeInTree,
        consumedNodesInTree: prevConsumedNodesInTree,
      }) ===
      JSON.stringify({
        node: nextProps.node,
        activeNodeInTree: nextActiveNodeInTree,
        consumedNodesInTree: nextConsumedNodesInTree,
      })
    );
  }
);

/* istanbul ignore next */
const renderTextNode = ({ text, key }: { text: string; key: string }, isBlurred?: boolean) => {
  // if text fits a url regex, then add the class "break-all"
  const urlRegex = /((http|https):\/\/[^\s]+)/g;
  const isUrl = urlRegex.test(text);

  return (
    <span key={key} className={cx(isBlurred && 'blur', isUrl && 'break-all')}>
      {text}
    </span>
  );
};
const isQuestion = (scrollTarget: ISection | IQuestion | undefined): scrollTarget is IQuestion => {
  return !!(scrollTarget as IQuestion)?.questionText;
};

const isDocumentTagNode = (node: DocumentNode): node is DocumentTagNode => node.type !== 'text';

const isQuestionTextNode = (node: DocumentNode): node is DocumentTagNode & { questionId: string } =>
  node.type !== 'text' && !!node.questionId;

/*
  This calculates for a document a Map from a node key to all consumed question IDs in this node or any of its descendants.
  This makes it easy to look up if a node or its descendants has to be rerendered because the highlighted nodes within that subtree have changed.
*/
const getNodeKeyToAllConsumedQuestionsOfAncestors = (documentNodes: DocumentNode[]): Map<string, Set<string>> => {
  const nodeKeyToConsumedQuestion = new Map<string, Set<string>>();
  const addNodeKeysRecursively = (
    node: DocumentNode,
    ancestorKeys: string[] = [],
    newAncestorKeys = [...ancestorKeys, node.key]
  ) => {
    if (isQuestionTextNode(node)) {
      newAncestorKeys.forEach((nodeKey) => {
        if (nodeKeyToConsumedQuestion.has(nodeKey)) {
          nodeKeyToConsumedQuestion.get(nodeKey)!.add(node.questionId);
          if (node.questionId.includes(questionListIdIndexSeparator)) {
            // This magic string ("!!!") is to allow us to check for non-list questions used in list inputs
            nodeKeyToConsumedQuestion.get(nodeKey)!.add('!!!' + node.questionId.split(questionListIdIndexSeparator)[0]);
          }
        } else {
          nodeKeyToConsumedQuestion.set(nodeKey, new Set([node.questionId]));
          if (node.questionId.includes(questionListIdIndexSeparator)) {
            // This magic string ("!!!") is to allow us to check for non-list questions used in list inputs
            nodeKeyToConsumedQuestion.get(nodeKey)!.add('!!!' + node.questionId.split(questionListIdIndexSeparator)[0]);
          }
        }
      });
    }
    if (isDocumentTagNode(node)) {
      node.children?.forEach((child) => addNodeKeysRecursively(child, newAncestorKeys));
    }
  };
  documentNodes.map((documentNode) => addNodeKeysRecursively(documentNode));
  return nodeKeyToConsumedQuestion;
};

const isActiveQuestionAnswer = (activeScrollTarget: IQuestion | ISection | undefined, node: DocumentTagNode) =>
  activeScrollTarget?._id && node.questionId === activeScrollTarget._id;

const isInfluencedByActiveQuestionAnswer = (
  activeScrollTarget: IQuestion | ISection | undefined,
  node: DocumentTagNode
) =>
  isQuestion(activeScrollTarget) &&
  !!find(activeScrollTarget?.consumedByQuestionIds || [], (consumedById) =>
    !consumedById.includes(questionListIdIndexSeparator) && node.questionId?.includes(questionListIdIndexSeparator)
      ? consumedById === node.questionId?.split(questionListIdIndexSeparator)[0]
      : consumedById === node.questionId
  );

function isAnchorElementAttributes(
  attributes?: React.HTMLAttributes<JSX.Element> | React.AllHTMLAttributes<JSX.Element>
): attributes is React.AllHTMLAttributes<JSX.Element> {
  return !!(attributes as React.AllHTMLAttributes<JSX.Element>)?.href;
}

export default OutputView;

export interface HeaderIndexes {
  DieterHeading1: number[];
  DieterHeading2: number[];
  DieterHeading3: number[];
  DieterHeading4: number[];
  DieterHeading5: number[];
  'page-break': number[];
}

const getHeaderIndexes = (nodes: DocumentTagNode[]): HeaderIndexes => {
  const headerTypes = ['DieterHeading2', 'DieterHeading3', 'DieterHeading4', 'page-break'];
  let headerIndexes = {} as HeaderIndexes;
  for (const type of headerTypes) {
    headerIndexes = {
      ...headerIndexes,
      [type]: nodes
        .map((node, idx) => ((node.attributes?.className === type || node.attributes?.id == type) && idx) || -1)
        .filter((n) => n >= 0),
    };
  }
  return headerIndexes;
};

const replaceNumberPrefix = (node: DocumentTagNode, i: number): any => {
  const numberPrefix = node?.children?.[0] as DocumentTagNode;
  const textNode = numberPrefix.children?.[0] as DocumentTextNode;
  try {
    return {
      ...node,
      children: [
        {
          ...numberPrefix,
          children: [{ ...numberPrefix.children?.[0], text: textNode.text.replace(/(\d+)(.*)/, `${i}$2`) }],
        },
        ...(node.children?.slice(1) || []),
      ],
    };
  } catch (error) {
    return node;
  }
};

const splitDocument = (document: DocumentNode[], documentNumber: number) => {
  // this splits the nodes up by 'page-break'
  const firstNode = Object.assign({}, document[0]) as DocumentTagNode;
  const nodes = (firstNode?.children || []) as DocumentTagNode[];
  const headerIndexes = getHeaderIndexes(nodes);
  const docSlices: DocumentTagNode[][] = [];

  // split nodes by page-break, where the split-point is found in headerIndexes['page-break']
  headerIndexes['page-break'].forEach((index, i, pagebreakindexes) => {
    if (i === 0) {
      docSlices.push(nodes.slice(0, index));
      return;
    }
    const prevIndex = pagebreakindexes[i - 1] + 1;
    docSlices.push(nodes.slice(prevIndex, index));
    if (i === pagebreakindexes.length - 1) docSlices.push(nodes.slice(index + 1, nodes.length));
  });

  const mySlice = ((documentNumber > 0 && documentNumber <= docSlices.length && [...docSlices[documentNumber - 1]]) || [
    ...nodes,
  ]) as DocumentTagNode[];

  // now we need to fix the enumeration in the "number-prefix" tag in all subsequent h2,h3... tags, since we slice documents in the middle
  // the numbering should always start with 1
  const sliceHeaderIndexes = getHeaderIndexes(mySlice);

  let h2idx = 0;
  const numberedSlice = mySlice.map((node, idx) => {
    if (Object.values(sliceHeaderIndexes).flat(2).includes(idx)) {
      if (sliceHeaderIndexes.DieterHeading2.includes(idx)) h2idx++;
      return replaceNumberPrefix(node, h2idx);
    } else {
      return node;
    }
  }) as unknown as DocumentTagNode[];

  firstNode.children = numberedSlice;
  return [firstNode];
};
