import { useNavigation } from '@app/hooks/useNavigation';
import { useQueryParams } from '@app/hooks/useQuestionaireRoute';
import { FileShareToken, parseJwt } from '@app/utils/parseJwt';
import LangSelect from '@components/block/Header/LangSelect';
import { Button, FileItem, FileUpload } from '@components/ui';
import { useGetShareTokenJWTQuery } from '@dieterApi/sharetoken/useGetShareTokenJWTQuery';
import { UserFile } from '@dieterApi/user/useUserQuery';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './fileshare.sass';

export default function FileShare() {
  const { t } = useTranslation();
  const query = useQueryParams();
  const { navigation, setNavigation } = useNavigation();
  const token = query.get('token') || '';
  const { data: shareTokenJWT } = useGetShareTokenJWTQuery({
    variables: { id: token },
  });
  const jwt = shareTokenJWT?.getShareTokenJWT?.jwt;
  const tokenInfo = jwt ? parseJwt<FileShareToken>(jwt)! : undefined;
  const [addUserValue] = useUserValueAddMutation();
  const [uploadedFile, setUploadedFile] = useState<UserFile | undefined>(undefined);

  // There is a timing problem in setting this to true
  // apparently the navigation context is not yet set
  useEffect(() => {
    if (navigation.isPrintView) return;
    setNavigation((nav) => void (nav.isPrintView = true));
  }, [navigation]);

  useEffect(() => {
    return () => {
      setNavigation((nav) => void (nav.isPrintView = false));
    };
  }, []);
  // const thisDoc = user?.documents.find((doc) => doc.id === currentParams.documentId);

  const isUpload = tokenInfo?.type === 'upload';

  const handleUploadComplete = (file: UserFile) => {
    setUploadedFile(file);
    addUserValue({
      variables: {
        key: 'DATA_PROCESSOR_TOMS',
        value: file.id,
        index: tokenInfo!.userValueIndex,
        token: jwt,
      },
    });
  };

  return (
    <div className="dtFileShare flex flex-col gap-20">
      <div className="fixed top-6 right-6">
        <LangSelect languages={['de', 'en']} inHeader={false} />
      </div>
      <div className="header flex">
        <h1 className="font-semibold text-2xl">
          {t('route.file.header', isUpload ? 'Einladung zum Upload' : 'Dateifreigabe', {
            context: isUpload ? 'isUpload' : undefined,
          })}
        </h1>
        <img src="/assets/images/dieter_logo.svg" alt="Dieter" />
      </div>

      {verifyToken(tokenInfo) ? (
        <>
          {isUpload ? (
            <>
              <Trans t={t} i18nKey="route.file.upload.form_header" values={tokenInfo}>
                <p>
                  {/* @ts-expect-error */}
                  {{ companyName: tokenInfo.companyName || '' }} bittet dich, eine Datei in das
                  Datenschutz-Managementsystem "Dieter" hochzuladen.
                </p>
                <p>
                  {/* @ts-expect-error */}
                  Geforderter Inhalt der Datei: <strong>{{ description: tokenInfo.description || '' }}</strong>
                </p>
              </Trans>
              <div className="flex flex-col gap-2">
                {!uploadedFile ? (
                  <Trans t={t} i18nKey="route.file.upload.file_input">
                    <FileUpload topicId={tokenInfo!.topicId!} onComplete={handleUploadComplete} token={jwt} />
                    <p className="mt-3">Du hast noch keine TOMs? "Dieter" hilft dir bei der Erstellung.</p>
                    <a href="https://www.dieter-datenschutz.de/toms" target="_blank" rel="noreferrer">
                      Jetzt ausprobieren
                    </a>
                  </Trans>
                ) : (
                  <>
                    <FileItem file={uploadedFile} withActions={false} />
                    <p className="flex gap-1 text-success pt-3 items-center">
                      <CheckCircleIcon />
                      <Trans
                        t={t}
                        i18nKey="route.file.upload.file_input_uploaded"
                        values={{ value: tokenInfo?.companyName || '' }}
                      >
                        <span className="text-xl">
                          Vielen Dank! {tokenInfo?.companyName || ''}
                          wird über den Upload informiert.
                        </span>
                      </Trans>
                    </p>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <Trans t={t} i18nKey="route.file.sharing" values={tokenInfo}>
                <p>
                  {/* @ts-expect-error */}
                  {{ companyName: tokenInfo?.companyName || '' }} hat die Datei {/* @ts-expect-error */}
                  <strong>"{{ fileName: tokenInfo?.fileName || '' }}"</strong> für dich zum Download freigegeben.
                </p>
              </Trans>
              <Button onClick={() => {}} data-testid={'button-submit'}>
                {t('common.action.download', 'Herunterladen')}
              </Button>
            </>
          )}
        </>
      ) : (
        <p>
          {t(
            'route.file.invalid_link_message',
            'Jemand hat versucht eine Datei für dich freizugeben oder einen Upload zu ermöglichen. Leider ist der Link ungültig.'
          )}
        </p>
      )}
    </div>
  );
}

const verifyToken = (tokenInfo: FileShareToken | undefined): boolean => {
  if (!tokenInfo) return false;
  if (tokenInfo.type === 'upload') {
    return !!(tokenInfo.topicId && tokenInfo.userValueIndex);
  }
  if (tokenInfo.type === 'download') {
    return !!tokenInfo.fileId;
  }
  return false;
};
